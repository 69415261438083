import * as types from '../../../actions/action-types';
import { SampleMediaRequestsAction } from './sample-media-request.actions';
import { MediaRequest } from '../../../models/media-request';
import { MediaSetAction } from '../../../actions/media-set.actions';

import initialState from '../../../reducers/initialState';


export function SampleMediaRequestReducer(
  state: MediaRequest[] = initialState.sampleMediaRequests,
  action: SampleMediaRequestsAction | MediaSetAction
): MediaRequest[] {
  switch (action.type) {
    case types.GET_SAMPLE_MEDIA_REQUESTS_SUCCESS:
      return action.sampleMediaRequests;
    case types.CREATE_MEDIA_SET_SUCCESS:
      return [...state, Object.assign({}, action.mediaRequest)];
    case types.CREATE_MEDIA_FILE_SET_SUCCESS:
    case types.SUBMIT_MEDIA_SET_SUCCESS:
    case types.MEDIASET_UPDATED_SUCCESS:
    case types.FILE_REMOVE_SUCCESS:
      // media sets minus the one we want to mutate
      const mediaSets = state.filter(msets => msets.mediaSetId !== action.mediaRequest.mediaSetId);

      // return the original media sets plus the mutated one
      return [...mediaSets, Object.assign({}, action.mediaRequest)];
  
    default:
      return state;
  }
  // change
}

export function AddLearnerErrorReducer(
  state: boolean = initialState.showAddLearnerError,
  action: SampleMediaRequestsAction
): boolean {
  switch (action.type) {
    case types.ADD_LEARNER_ERROR:
      return action.showAddLearnerError;
    case types.CLOSE_SHOW_LEARNER_ERROR_MODAL:
        return action.showAddLearnerError;
    default:
      return state;
  }

}

export const SampleMediaRequestCompletedReducer = (
  state: boolean = initialState.isGetSampleMediaRequestCompleted,
  action: SampleMediaRequestsAction | MediaSetAction
) => {
  if (action.type === types.GET_SAMPLE_MEDIA_REQUESTS_COMPLETED) {
    return action.isGetSampleMediaRequestCompleted;
  }else{
    return state;

  }
  // switch (action.type) {
  //   case types.GET_SAMPLE_MEDIA_REQUESTS_COMPLETED:
  //     return action.isGetSampleMediaRequestCompleted;
  //   default:
  //     return state;
  // }
}

export const SampleMediaRequestStartedReducer = (
  state: boolean = initialState.isGetSampleMediaRequestStarted,
  action: SampleMediaRequestsAction | MediaSetAction
) => {
  if(action.type === types.GET_SAMPLE_MEDIA_REQUESTS_STARTED){
    return action.isGetSampleMediaRequestStarted;
  }else{
    return state;
  }
  // switch (action.type) {
  //   case types.GET_SAMPLE_MEDIA_REQUESTS_STARTED:
  //     return action.isGetSampleMediaRequestStarted;
  //   default:
  //     return state;
  // }
}