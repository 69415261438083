import { StoreState } from '../store/store-state';
import AuthForgeRock from '../actions/auth/auth';

const authForgeRock = new AuthForgeRock();

export const InitialState: StoreState = {
    hasGlobalError: false,
    learningProvider: { id: '9b88c438-99c7-f3c2-c31e-5c93cbcd040f', name: 'Pearson', code: '000' },
    ajaxCallsInProgress: 0,
    availabilities: [],
    sampleMediaRequests: [],
    isGetSampleMediaRequestCompleted: false,
    isGetSampleMediaRequestStarted: false,
    assessmentAssociate: null,
    auth: {
      error: '',
      timestamp: 0,
      loading: false,
      authenticated: authForgeRock.isAuthenticated(),
      proxyName:authForgeRock.getProxyName(),
      proxyId:authForgeRock.getProxyId(),
      profile: authForgeRock.getProfile()
    },
    callback: {
      error: '',
      loading: false
    },
    mediaUploadStatus: [],
    globalAlertMessages: [],
    userDetailsByForgerock: {
      associatedLearningProviders:[],
      isUserPermissionValid: true,
      permissionGroups: [],
      userPermissions:{
        lwtReadAccess: true,
        lwtWriteAccess: true
      }
  }, 
  userPermissionValidated:true,  
  showCreateMenu : false,
  selectedUserId: "0",
  showAddLearnerError: false,
  isSessionAlive: true
  
}

export default InitialState;
