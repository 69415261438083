import * as React from 'react';
import { userTypeEnums} from '../features/sample-media-request/shared/enum';

import {Button} from 'ukas.uux.react-components';

interface ConfirmationModalProps {
    cancelBtnHandler : ( event: React.UIEvent<HTMLSpanElement>) => void;
    userType: string;
    isAccountLocked: boolean
}


const AccessDeniedModal = ({ cancelBtnHandler, userType , isAccountLocked }: ConfirmationModalProps, ) =>{
  const errorMessageFragment = (userType.toUpperCase() === userTypeEnums.EXTERNAL_USER.toUpperCase())
    ? <> Your account might be locked please 
      <a target="_blank" href="https://qualifications.pearson.com/en/contact-us.html"> contact us.</a> </>
    : (userType.toUpperCase() === userTypeEnums.INTERNAL_USER.toUpperCase()) 
    ? 'Your account might be locked please raise an incident via myHelp.': 'Logging out...please wait';
  return ( <div>
        <div >
          <span>{errorMessageFragment}</span>
              </div> 
            <div className="pull-right"><br /><br />
            <Button
                onClick={cancelBtnHandler.bind(event)}
            >
                Close
            </Button>&nbsp;&nbsp;&nbsp;&nbsp;
            <p>&nbsp;</p>

      </div>
    </div>
     
      
   
  );
};

export default AccessDeniedModal;
