import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
const uuid = require('uuid/v4');

import { CreateMediaSet, StateProps, DispatchProps } from "./create-media-set";
import { StoreState } from "../../store/store-state";

import {
  MediaSetAction,
  createMediaSet as createMediaSetAction
} from "../../actions/media-set.actions";
import { MediaRequest } from "../../models/media-request";
import { getEnv } from '../../actions/user-details-by-forgerock.actions'

const mapStateToProps = (state: StoreState): StateProps => {
  
  const learningProvider = state.learningProvider;
  const userPermissionValidated:boolean = state.userPermissionValidated;
  const mediaSet = new MediaRequest();
  mediaSet.standardsVerifierId = newGuid();
  const showCreateMenu:boolean = state.showCreateMenu;
  return { mediaSet, learningProvider , userPermissionValidated, showCreateMenu};
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<StoreState, void, MediaSetAction>
): DispatchProps => ({
  onSave: (mediaSet: MediaRequest) => dispatch(createMediaSetAction(mediaSet)),
  getEDetails: () => dispatch(getEnv()),
});

const newGuid = (): string => {
  return uuid();
}

export default connect<StateProps>(
  mapStateToProps,
  mapDispatchToProps
)(CreateMediaSet);

