import { Types as UserActionTypes } from "./context-api-action-types";
import { UserAction, UserLoginDataType } from "./actions";

export const userDataReducer = (
  state: UserLoginDataType,
  action: UserAction
) => {
  if (action.type === UserActionTypes.UserLoggedInAction) {
    return { ...state, ...action.payload };
  } else {
    return state;
  }
};
