import * as React from "react";

import AssessmentAssociateAvailabilityListRow from "./sample-media-requests-list-row";
import { MediaRequest } from "../../../../models/media-request";
import moment from "moment";
import { Table, TextInput, Select } from 'ukas.uux.react-components';
interface SampleMediaRequestsListProps {
  sampleMediaRequests: MediaRequest[];
  onSelect: (mediaRequestId: string, event: Event) => void;
  onSort: (item: string) => void;
  getIconName: (columnName: string) => string;
  onFieldChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onYearChange: (event: React.FormEvent<HTMLSelectElement>) => void;
  displaySelect  : boolean;
}

const SampleMediaRequestsList = ({
  sampleMediaRequests,
  onSelect,
  onSort,
  getIconName,
  onFieldChange,
  onYearChange,
  displaySelect
}: SampleMediaRequestsListProps) => {
  const checkYear = moment();
  const month: string = checkYear.format("M");
  let getYear = checkYear.format("YYYY");
  // tslint:disable-next-line: radix
  if (parseInt(month) < 9) {
    const nextyear = moment().subtract(1, "years");
    getYear = nextyear.format("YYYY");
  }
  // tslint:disable-next-line: radix
  const currentYear = "(" + (parseInt(getYear)) + "/" + (parseInt(getYear) + 1)  + ")";
  const prevYear =
    // tslint:disable-next-line: radix
    "(" + (parseInt(getYear) - 1) + "/" + (parseInt(getYear) ) + ")";
  const currentYearTxt = "Current Year " + currentYear;
  const prevYearTxt = "Previous Year " + prevYear;
  const selectedYear =
    localStorage.getItem("selectedYear") === getYear
      ? currentYearTxt
      : prevYearTxt;
  return (
    <div>
      <div className="pe-table-toolbar">
        <div className="pull-left">
          {" "}
          {displaySelect && (
          <TextInput
            id="searchTerm"
            label="Search"
            onChange={onFieldChange} maxLength={10}
          />)}
        </div>
        {displaySelect && (
        <div className="pull-right">
          {" "}
          <Select
            id="academicYear"
            label="Select Academic Year"
            onChange={onYearChange}
            value={selectedYear}
          >
            <option
              value={prevYearTxt}
            >
              {prevYearTxt}
            </option>
            <option
              value={currentYearTxt}
            >
              {currentYearTxt}
            </option>
          </Select>
        </div>)}
        <div className="clearfix" />
      </div>
      {displaySelect && (
      <Table responsive="xl">
        <thead>
          
          <tr>
            <td>Qualification Group Code</td>
            <td>Qualification Group</td>
            <td>AA Number</td>
            <td>SV</td>
            <td>No. of Learners</td>
            <td>Deadline</td>
            <td>Status</td>
          </tr>
        </thead>
        <tbody>
          {displaySelect && sampleMediaRequests.map((sampleMediaRequest: MediaRequest) => (
            <AssessmentAssociateAvailabilityListRow
              key={sampleMediaRequest.mediaSetId}
              sampleMediaRequest={sampleMediaRequest}
              onSelect={onSelect}
            />
          ))}
        </tbody>
      </Table>)}
    </div>
  );
};

export default SampleMediaRequestsList;
