import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import _ from "lodash";
import { MediaRequest } from '../../models/media-request';

import {Button, TextInput} from 'ukas.uux.react-components';

export interface StateProps {
  sampleMediaRequests: MediaRequest[] | null;
  learningProviderId:string;
}

export interface DispatchProps {
  getMediaSetsByLearningProviderCode: (learningProviderCode: string) => Promise<void>;
  getSampleMediaRequests: (learningProviderId: string) => Promise<void>;
}

export type OwnProps = RouteComponentProps<{proxyName:string}>;

interface Props extends DispatchProps, StateProps, OwnProps {
  learningProviderCode:string 
  sampleMediaRequests:MediaRequest[] ;
  cancelBtnHandler: any;
  onFieldChange: (event: React.FormEvent<HTMLInputElement>) => void;
  errors: any;
}

interface State {
  learningProviderCode:string;
  validations: Validations,
  
}
class Validations {
  errors:any = {};
  validationFlags: ValidationFlags = new ValidationFlags;
}

class ValidationFlags {  
  
  learningProviderCode:boolean = false;
  
}

export class SelectLearningProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      learningProviderCode:'',
      validations: new Validations()
      
    }

    this.onSearchClickHandler =  this.onSearchClickHandler.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  render() {
    const { learningProviderCode } = this.state;
    return (
      <div>
        <div className="pe-template__single">
          &nbsp;
        </div>
        <div className="pe-template__single">
        Search for a learning provider with sample requests to populate the request screen.
        </div>
        <div className="pe-template__single content">
        <form className="pe-form" >
          <div className="pe-form--group">
            <div className="searchBox">
              <TextInput id="learningProviderCode"
              label='Learning Provider Code'
              value={learningProviderCode}
              feedbackType={this.state.validations.errors.learningProviderCode !== undefined ? 'invalid': 'valid'}
              feedback={this.state.validations.errors.learningProviderCode}
              onChange={this.onFieldChange} maxLength={10} />
              </div>&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="searchButton">
                <Button
                id="saveButton"
                variant="primary"
                onClick={(e)=>this.onSearchClickHandler(e)} 
              >
                Search
              </Button></div>
          </div>
        </form>
        </div>
      </div>

    );
  }

  // onFieldChange(event: React.FormEvent<HTMLInputElement>) {
  //   event.preventDefault();

  //   const element = event.target as HTMLInputElement;    
  //   const mediaRequest = Object.assign({}, this.state.mediaRequest);
  //   mediaRequest[element.id] = element.value;
    
  //   const validations = Object.assign({}, this.state.validations, this.validateInputElement(element.id, element.value));

  //   return this.setState({ mediaRequest, validations });
  // }
  validateInputElement(prop:string, value:any):Validations {       
    const result:Validations = Object.assign({}, this.state.validations);
    if(prop === 'learningProviderCode'){
      if (value === '') {
        result.errors[prop] = 'required';
        result.validationFlags[prop]= false;
      }else if(value === 'empty'){
        result.errors[prop] = 'There are no sample requests for this learning provider';
        result.validationFlags[prop]= false;
      } else {
        result.errors[prop] = undefined;
        result.validationFlags[prop] = true;
      }
    }
    // switch(prop) {
    //   case 'learningProviderCode' :
      
    //   {
    //     if (value === '') {
    //       result.errors[prop] = 'required';
    //       result.validationFlags[prop]= false;
    //     }else if(value === 'empty'){
    //       result.errors[prop] = 'There are no sample requests for this learning provider';
    //       result.validationFlags[prop]= false;
    //     } else {
    //       result.errors[prop] = undefined;
    //       result.validationFlags[prop] = true;
    //     }
    //   }
    // }

    return result;
  }
  onSearchClickHandler(event:any){
    event.preventDefault();
     const prevProxyId = localStorage.getItem('prevProxyId');
       
    this.props.getMediaSetsByLearningProviderCode(this.state.learningProviderCode).then((response ) => {
      if(this.props.sampleMediaRequests.length >0){
        this.props.cancelBtnHandler();
        this.props.history.push(`/sample-requests/`);
      }else{
        const validations = Object.assign({}, this.state.validations, this.validateInputElement('learningProviderCode', "empty"));
        (prevProxyId === null)?this.props.getSampleMediaRequests(this.props.learningProviderId):this.props.getMediaSetsByLearningProviderCode(prevProxyId+'');
        return this.setState({validations });
      }
    }).catch((e) => {
      console.log("")
    });
  }
  onFieldChange(event: React.FormEvent<HTMLInputElement>) {
    event.preventDefault();

    const element = event.target as HTMLInputElement;    
    const validations = Object.assign({}, this.state.validations, this.validateInputElement(element.id, element.value));

    return this.setState({ learningProviderCode:element.value,validations });
  }
}
export default SelectLearningProvider;
