export class Address {
  addressLines: string[];
  postcode: string;
  type: string;
}

export class AssessmentAssociate {
  id: string;
  aaNumber: string;
  title: string;
  firstName: string;
  surname: string;
  emailAddress: string;
  telephone: string;
  mobile: string;
  addresses: Address[];
}

export class Availability {
  id: string;
  assessmentAssociateId: string;
  startDate: Date;
  endDate: Date;
  singleDay: boolean;
}
