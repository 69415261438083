import * as React from "react";
import { NavLink } from "react-router-dom";

// import LoadingDots from './LoadingDots';
// const PearsonElementsSDK = require("@pearson-components/elements-sdk/build/dist.elements-sdk");

import { UserLearningProvider } from "models/user-details-forgerock-request";
require("./nav.scss");
import DropdownMenu from '../../features/sample-media-request/shared/dropdown-menu';

interface NavProps {
  loading: boolean;
  isAuthenticated: boolean;
  userDetails: UserLearningProvider[];
  userType: string;
  proxyName: string;
  selectedUserType: string;
  // appendedUserDetails:UserLearningProvider[];
  onUSerDetailsDropdownChange: (
    lpName: string,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  userPermissionValidated: boolean;
  learningProviderFilterLabel: string;
  showCreateMenu: boolean;
  proxyLPName:string;
}

const defaultOption = {
  learningProviderId: 0,
  learningProvider: {
    vqLearningProviderCode: "",
    gqLearningProviderCode: "",
    learningProviderId: 0,
    learningProviderName: "Select a Learning Provider",
    showCreateMenu: false
  }
};

export const Nav = ({
  loading,
  selectedUserType,
  isAuthenticated,
  onUSerDetailsDropdownChange,
  userPermissionValidated,
  userType,
  proxyName,
  userDetails,
  learningProviderFilterLabel,
  showCreateMenu,
  proxyLPName
}: NavProps) => (
  <div className="barStyleTabs">
    <ul className="tabs__labels" role="tablist">
      <li role="presentation">
        <NavLink
          to="/"
          exact={true}
          className="pe-label light"
          activeClassName="activeTab"
        >
          Home
        </NavLink>
      </li>
      {isAuthenticated && userType && userType.toUpperCase() === "INTERNAL" && (
        <li role="presentation">
          <NavLink
            to="/select-a-learning-provider"
            className="pe-label light"
            activeClassName="activeTab"
          >
            Select a Learning Provider 
          </NavLink>
        </li>
      )}
      {isAuthenticated && (
        <li role="presentation">
          <NavLink
            to="/sample-requests"
            className="pe-label light"
            activeClassName="activeTab"
          >
            Sample Requests
          </NavLink>
        </li>
      )}
      {/* Link is not accessible to users */}
      {/* {isAuthenticated && showCreateMenu && (
        <li role="presentation">
          <NavLink
            to="/createMediaSet"
            className="pe-label light"
            activeClassName="activeTab"
          >
            Create Media Set
          </NavLink>
        </li>
      )} */}
      {/* <li>{loading && <LoadingDots interval={100} dots={20} />}</li> */}
    </ul>

    {userType && userType.toUpperCase() === "INTERNAL" && proxyName && <span className="pull-right barStyleText">{proxyName}</span>}

    {userPermissionValidated && userType && 
      userType.toUpperCase() === "EXTERNAL" &&
      userDetails.length === 1 && (
        <span className="pull-right barStyleText">
          {
            userDetails[0].learningProvider.vqLearningProviderCode
            ? 
            userDetails[0].learningProvider.gqLearningProviderCode?

            userDetails[0].learningProvider.vqLearningProviderCode +
              "/" +
              userDetails[0].learningProvider.gqLearningProviderCode +
              " " +
              userDetails[0].learningProvider.learningProviderName
            :
            userDetails[0].learningProvider.vqLearningProviderCode +
              " " +
              userDetails[0].learningProvider.learningProviderName:

              userDetails[0].learningProvider.gqLearningProviderCode +
              " " +
              userDetails[0].learningProvider.learningProviderName
        }
        </span>
      )}
    {/* {isAuthenticated  && userType.toUpperCase() === 'EXTERNAL' &&  userPermissionValidated && userDetails.length > 1 &&
    <span className="pull-right barStyleDropdown ">
         
          <PearsonElementsSDK.Dropdown
            type="button"
            label={learningProviderFilterLabel}
            id="unitsFilterButton"
            changeHandler={onSelectUserDetails}
          >
            <PearsonElementsSDK.DropdownItem
              dropdownId="0"
              selectValue="0"
              label="Select a Learning Provider"
              type="button"
            />
         
           {userDetails.map((userDetail :UserLearningProvider , index) => (
              <PearsonElementsSDK.DropdownItem
                key={userDetail.learningProvider.learningProviderId}
                dropdownId={userDetail.learningProvider.learningProviderId}
                selectValue= {userDetail.learningProvider.learningProviderId }
                label={userDetail.learningProvider.vqLearningProviderCode ? 
                  userDetail.learningProvider.vqLearningProviderCode+' /'+userDetail.learningProvider.gqLearningProviderCode +' '+ userDetail.learningProvider.learningProviderName
                :
                userDetail.learningProvider.gqLearningProviderCode +' '+ userDetail.learningProvider.learningProviderName
              }
                type="button"
              />
            ))}
          </PearsonElementsSDK.Dropdown>
        </span>
     } */}

    {isAuthenticated && userType &&
      userType.toUpperCase() === "EXTERNAL" &&
      userPermissionValidated &&
      userDetails.length > 1 && (
        <span className="pull-right barStyleDropdown ">
          <DropdownMenu
            render={menuItems(userDetails, onUSerDetailsDropdownChange)}
            selectedOptionText= {proxyLPName}
          />
          {/* <PearsonElementsSDK.Select
            id="proxyExtUserDropdown"
            options={[defaultOption, ...userDetails].map(userDetail =>
              userDetail.learningProvider.vqLearningProviderCode
                ? 
                userDetail.learningProvider.gqLearningProviderCode?

                userDetail.learningProvider.vqLearningProviderCode +
                  "/" +
                  userDetail.learningProvider.gqLearningProviderCode +
                  " " +
                  userDetail.learningProvider.learningProviderName
                :
                userDetail.learningProvider.vqLearningProviderCode +
                  " " +
                  userDetail.learningProvider.learningProviderName:

                 userDetail.learningProvider.gqLearningProviderCode +
                  " " +
                  userDetail.learningProvider.learningProviderName
            )}
            changeHandler={onUSerDetailsDropdownChange}
            selectedOption={
              localStorage.getItem("selectedUserType")
                ? localStorage.getItem("selectedUserType")
                : selectedUserType
            }
          /> */}
        </span>
      )}
  </div>
);

const menuItems = (
  userDetails: UserLearningProvider[],
  clickHandler:(
      lpName: string,
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => void
  ) =>
    () =>(
     <ul>{
          [defaultOption, ...userDetails].map((userDetail, i) =>(

            <li key={i}>
               <a onClick={clickHandler.bind(event, userDetail.learningProvider.vqLearningProviderCode 
                ? userDetail.learningProvider.vqLearningProviderCode+ " " +userDetail.learningProvider.learningProviderName 
                : userDetail.learningProvider.learningProviderName)}>
                {
                  userDetail.learningProvider.vqLearningProviderCode
                  ? 
                  userDetail.learningProvider.gqLearningProviderCode?
  
                  userDetail.learningProvider.vqLearningProviderCode +
                    "/" +
                    userDetail.learningProvider.gqLearningProviderCode +
                    " " +
                    userDetail.learningProvider.learningProviderName
                  :
                  userDetail.learningProvider.vqLearningProviderCode +
                    " " +
                    userDetail.learningProvider.learningProviderName:
  
                   userDetail.learningProvider.gqLearningProviderCode +
                    " " +
                    userDetail.learningProvider.learningProviderName
                }
              </a>
            </li>
          ))
        }
  </ul>
)   

export default Nav;
