import * as React from 'react';

import {Button} from 'ukas.uux.react-components';

interface ConfirmationModalProps {
    cancelBtnHandler : ( event: React.UIEvent<HTMLSpanElement>) => void;
    successBtnHandler : ( event: React.UIEvent<HTMLSpanElement>) => void;
    conmfirmMsgRender:() => JSX.Element;
}


const ConfirmationModalGeneric = ({ successBtnHandler, cancelBtnHandler, conmfirmMsgRender }: ConfirmationModalProps) =>{
  return ( 
    <div>
      <div >
        {conmfirmMsgRender()}
      </div>
      
      <div className="pull-right"><br /><br />
        <Button
            onClick={cancelBtnHandler}>
            Cancel
        </Button>&nbsp;&nbsp;&nbsp;&nbsp;
        
        <Button variant="primary" 
            onClick={successBtnHandler}>
            Ok
        </Button>
        <br /><br />         
      </div>
    </div>
   
  );
};

export default ConfirmationModalGeneric;
