// CONTEXT-API CONSTANTS
// export const SIGN_IN_STATUS = "SIGN_IN_STATUS";
// export type SIGN_IN_STATUS = typeof SIGN_IN_STATUS;

// export const USER_LOGGEDIN = 'userLoggedIn';
// export type USER_LOGGEDIN = typeof USER_LOGGEDIN;

// export const USER_INFO = 'userInfo';
// export type USER_INFO = typeof USER_INFO;

export enum Types {
  UserLoggedInAction = "USER_LOGGEDIN_ACTION",
}
