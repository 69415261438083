import { Action } from 'redux';
import * as types from './action-types';

interface AjaxCallAction extends Action {
  type: types.BEGIN_AJAX_CALL;
}

interface AjaxCallErrorAction extends Action {
  type: types.AJAX_CALL_ERROR;
  error: string;
}

export type AjaxCallActions = AjaxCallAction | AjaxCallErrorAction;

export const beginAjaxCall = () : AjaxCallAction => {
  return { type: types.BEGIN_AJAX_CALL };
};

export const ajaxCallError = (error: string): AjaxCallErrorAction => {
  return { type: types.AJAX_CALL_ERROR, error };
};
