import { combineReducers } from 'redux';
import { StoreState } from '../store/store-state';
import { ajaxStatusReducer as ajaxCallsInProgress } from './ajax-status.reducer'; 
import { AssessmentAssociateReducer as assessmentAssociate } from './assessment-associate.reducer';
import { AvailabilityReducer as availabilities } from './availability.reducer';
import { SampleMediaRequestReducer as sampleMediaRequests } from '../features/sample-media-request/shared/sample-media-request.reducer';
import { SampleMediaRequestCompletedReducer as isGetSampleMediaRequestCompleted } from '../features/sample-media-request/shared/sample-media-request.reducer';
import { SampleMediaRequestStartedReducer as isGetSampleMediaRequestStarted } from '../features/sample-media-request/shared/sample-media-request.reducer';
import { AuthReducer as auth } from './auth.reducer';
import { AddLearnerErrorReducer as showAddLearnerError} from '../features/sample-media-request/shared/sample-media-request.reducer';
import { ValidateUserPermissionsReducer as userPermissionValidated } from './user-details-by-forgerock.reducer';
import { CheckSessionReducer as isSessionAlive } from './user-details-by-forgerock.reducer';
import { GetEnvReducer as showCreateMenu } from './user-details-by-forgerock.reducer';
import { CallbackReducer as callback } from './callback.reducer';
import { globalErrorReducer as hasGlobalError } from './global-error.reducer'
import { learningProviderReducer as learningProvider } from './learning-provider.reducer';
import { selectedUserReducer as selectedUserId } from './learning-provider.reducer';
import { userDetailsByForgerockRequestReducer as userDetailsByForgerock} from './user-details-by-forgerock.reducer'
import { mediaUploadReducer as mediaUploadStatus } from './media-upload.reducer';
import { globalAlertReducer as globalAlertMessages } from '../features/global-alert-list/shared/gloal-alert-list.reducer';

const rootReducer = combineReducers<StoreState>({
    hasGlobalError,
    assessmentAssociate,
    availabilities,
    sampleMediaRequests,
    isGetSampleMediaRequestCompleted,
    isGetSampleMediaRequestStarted,
    ajaxCallsInProgress,
    auth,
    callback,
    learningProvider,
    userPermissionValidated,
    showCreateMenu,
    mediaUploadStatus,
    globalAlertMessages,
    userDetailsByForgerock,
    selectedUserId,
    showAddLearnerError,
    isSessionAlive
    
});

export default rootReducer;  