import * as React from 'react';
// import { FormattedDate } from 'react-intl';
const PearsonElementsSdk = require('@pearson-components/modal');
import { MediaRequestFile } from '../../../../models/media-request';
import { LearnerUnit } from '../../../../models/LearnerUnit';
import LearnerMediaList from './learner-media-list';
interface ManageUploadModalProps {
  showModal: boolean;
  cancelModalHandler: () => void;
  successModalHandler: () => void;
  getlastLinkedLearnerConfirmationMsg: () => JSX.Element;
  cancellastLinkedLearnerModalHandler: ()=> void;
  successlastLinkedLearnerModalHandler: ()=> void;
  filterFilesForLearner: (
    mediaRequestFiles: MediaRequestFile[],
    learnerUnit: LearnerUnit
  ) => MediaRequestFile[];
  learnerUnit: LearnerUnit;
  files: MediaRequestFile[];
  islastLinkedLearner: boolean;
  onRemoveFile: (file:MediaRequestFile, learnerUnit:LearnerUnit, event: React.UIEvent<HTMLSpanElement>) => void;
  canRemoveFiles: boolean;
  isBusy: boolean;
}

const ManageUploadModal = ({
  showModal,
  cancelModalHandler,
  successModalHandler,
  filterFilesForLearner,
  islastLinkedLearner,
  getlastLinkedLearnerConfirmationMsg,
  cancellastLinkedLearnerModalHandler,
  successlastLinkedLearnerModalHandler,
  learnerUnit,
  files,
  onRemoveFile,
  canRemoveFiles,
  isBusy
}: ManageUploadModalProps) => {
  const text = {
    headerTitle: 'Manage Upload',
    bodyText: '',
    closeButtonSRText: 'Cancel',
    modalSaveButtonText: 'Ok',
    modalCancelButtonText: 'Cancel'
  };
  require('./manage-upload-modal.css'); 
  
  return (
    <PearsonElementsSdk.Modal
      isShown={showModal}
      text={text}
      footerVisible={false}
      cancelBtnHandler={cancelModalHandler}
      successBtnHandler={successModalHandler}
    >
      {learnerUnit !== null && (
        <LearnerMediaList
          files={filterFilesForLearner(files, learnerUnit)}
          learnerUnit={learnerUnit}
          onRemoveFile={onRemoveFile}
          canRemoveFiles={canRemoveFiles} 
          isBusy={isBusy}          
          islastLinkedLearner={islastLinkedLearner}
          getlastLinkedLearnerConfirmationMsg={getlastLinkedLearnerConfirmationMsg}
          cancellastLinkedLearnerModalHandler={cancellastLinkedLearnerModalHandler}
          successlastLinkedLearnerModalHandler= {successlastLinkedLearnerModalHandler}

        />
      )}
       {/* <div className="pull-right"><br /><br />
        <PearsonElementsSDK.Button onClick={cancelModalHandler.bind(event)}  btnType="primary">
            Close
        </PearsonElementsSDK.Button>
        <br /><br />         
      </div> */}
    </PearsonElementsSdk.Modal>
    
  );
};

export default ManageUploadModal;
