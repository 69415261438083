import * as React from 'react';
import { userTypeEnums} from './enum';


import {Button} from 'ukas.uux.react-components';
interface ConfirmationModalProps {
    cancelBtnHandler : ( event: React.UIEvent<HTMLSpanElement>) => void;
    userType: string;
}


const AccessDeniedModal = ({ cancelBtnHandler, userType }: ConfirmationModalProps, ) =>{
  const errorMessageFragment = (userType.toUpperCase() === userTypeEnums.EXTERNAL_USER.toUpperCase())
    ? <>  If you think you should have access, please contact your Exams Officer to update your account with the correct profile. Otherwise, please press
      <a target="_blank" href="https://qualifications.pearson.com/en/contact-us.html"> contact us.</a> </>
   : (userType.toUpperCase() === userTypeEnums.INTERNAL_USER.toUpperCase()) 
    ? 'If you think you should have access, please go to myStore and raise a PQS Requests incident.': 'Logging out...please wait';
  return (  <div>
    <div >
          You do not have the correct profile to access this application. <br /><br /> 
            <span>{errorMessageFragment}</span>
              </div> 
            <div className="pull-right"><br /><br />
            <Button
                onClick={cancelBtnHandler.bind(event)}
            >
                Close
            </Button>&nbsp;&nbsp;&nbsp;&nbsp;
            <p>&nbsp;</p>

      </div>
    </div>
     
      
   
  );
};

export default AccessDeniedModal;