import * as React from 'react';

import * as _ from 'lodash';
import SampleMediaRequestLearnersListRow from './sample-media-requests-learners-list-row';
import { MediaRequestFile } from '../../../../models/media-request';
import { LearnerUnit } from '../../../../models/LearnerUnit';
import { Table } from 'ukas.uux.react-components';

interface Props {
  sampleMediaRequestLearners: LearnerUnit[];
  mediaRequestFiles: MediaRequestFile[];
  units: Array<{ unitId: string; unitName: string }>;
  onSelectUnit: (event: any) => void;
  goBack: (event: Event) => void;
  canSubmit: () => boolean;
  submit: (event: Event) => void;
  allowUpload: boolean;
  showFiles: (
    learnerUnit: LearnerUnit,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  uploadFileEvent: (file: File[], unitId: string, learnerId: string) => void;
  unitFilterLabel: string;
  onSort: (item: string) => any;
  getIconName: (columnName: string) => string;
  onLoadSort: boolean;
  isConsortia: boolean;
}

export class SampleMediaRequestsLearnersTab extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }
  
  render() {
    const {
      sampleMediaRequestLearners,
      mediaRequestFiles,
      showFiles,
      uploadFileEvent,
      allowUpload,
      onLoadSort,
      isConsortia
    } = this.props;
    const sortedSampleMediaRequestLearners = onLoadSort ? _.sortBy(sampleMediaRequestLearners, ["unitName"]) : sampleMediaRequestLearners;

    return (
      <Table responsive="xl">
        <thead>
          <tr>
            <td>Learning Provider Code</td>
            <td>Learning Provider Name</td>
            <td>Learner Code</td>
            <td>Learner Name</td>
            <td>Unit</td>
            <td>No. of files</td>
            <th style={{ display: allowUpload ? '' : 'none' }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {sortedSampleMediaRequestLearners.map((learner: LearnerUnit, index) => (
            <SampleMediaRequestLearnersListRow
              isRejected= {mediaRequestFiles.filter(
                (f: MediaRequestFile) =>
                  (f.learnerIds.filter((l: string) => l === learner.learnerId)
                    .length > 0) && (f.unitId === learner.unitId) && (f.fileStatus === 'REJECTED')
              )}
              showIds={true}
              allowUpload={allowUpload}
              key={learner.learnerId + ':' + index}
              sampleMediaRequestLearner={learner}
              mediaRequestFiles={mediaRequestFiles.filter(
                (f: MediaRequestFile) =>
                  (f.learnerIds.filter((l: string) => l === learner.learnerId)
                    .length > 0) && (f.unitId === learner.unitId)
              )}
              uploadFileEvent={uploadFileEvent}
              showFiles={showFiles}
              isConsortia={isConsortia}
            />
          ))}
        </tbody>
      </Table>
    )
  }
}

export default SampleMediaRequestsLearnersTab;
