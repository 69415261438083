

export enum statusEnums {
    CREATE_REQUEST               = 'Create request',
    REQUEST_CREATION_IN_PROGRESS = 'Request Creation In Progress',
    REQUEST_RECEIVED             = 'Request Received',
    REQUEST_SUBMITTED            = 'Request Submitted',
    RECALL_PENDING              = 'Recall Pending',
    REQUEST_RECALLED             = 'Request Recalled',
}
export enum userTypeEnums {
    INTERNAL_USER = 'INTERNAL',
    EXTERNAL_USER = 'EXTERNAL'
}

export enum fileStatus {
    PREPARING = "PREPARING",
    REJECTED = "REJECTED",
    READY = "READY"
}

export enum requestStatusEnums {
    UPLOAD_SUBMITTED    = 'UPLOAD_SUBMITTED',
    SUBMIT_PENDING      = 'SUBMIT_PENDING',
    UPLOAD_IN_PROGRESS  = 'UPLOAD_IN_PROGRESS'

}
