import { Action } from 'redux';
import * as types from './action-types';

interface GlobalErrorAction extends Action {
  type: types.GLOBAL_ERROR;
  error: Error;
}

export type GlobalErrorActions = GlobalErrorAction;

export const globalError = (error: Error): GlobalErrorAction => {
  return { type: types.GLOBAL_ERROR, error };
};
