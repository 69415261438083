import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import * as _ from 'lodash';

import {
  SampleMediaRequests,
  StateProps,
  DispatchProps
} from './sample-media-request-list.component';

import {
  MediaUploadActions,
  uploadMedia
} from '../../../actions/media-upload.actions';
import {
  getMediaSetsByLearningProviderCode,
  
} from "../../../actions/select-learning-provider.actions";

import {
  getSampleMediaRequests,
  SampleMediaRequestsAction,
  refreshSampleMediaRequest,
  countSampleMediaRequest,
  getSampleMediaRequestCompleted,
  refreshMediaRequest
} from '../shared/sample-media-request.actions'; 
import { StoreState } from '../../../store/store-state';
import { MediaRequest } from '../../../models/media-request';
import { MediaUploadFile } from '../../../models/media-upload';

const mapStateToProps = (state: StoreState): StateProps => {
  const sampleMediaRequests = _.orderBy(state.sampleMediaRequests, (o: MediaRequest) => o.qualificationName, 'asc');

  return {
    learningProviderId: state.learningProvider.id,
    sampleMediaRequests,
    userPermissionValidated: state.userPermissionValidated,
    mediaUploadStatus: state.mediaUploadStatus,
    profile: state.auth.profile,
    isGetSampleMediaRequestCompleted: state.isGetSampleMediaRequestCompleted,
    isGetSampleMediaRequestStarted: state.isGetSampleMediaRequestStarted 
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    StoreState,
    void,
    SampleMediaRequestsAction | MediaUploadActions
  >
): DispatchProps => ({
  getSampleMediaRequests: learningProviderId => dispatch(getSampleMediaRequests(learningProviderId)),
  getSampleMediaRequestCompleted: status => dispatch(getSampleMediaRequestCompleted(status)),
  upload: (mediaUploadFile: MediaUploadFile) => dispatch(uploadMedia(mediaUploadFile)),
  refreshSampleMediaRequest: (mediaRequest: MediaRequest) => dispatch(refreshSampleMediaRequest(mediaRequest)),
  countSampleMediaRequest: (learningProviderId: string) => dispatch(countSampleMediaRequest(learningProviderId)),
  refreshMediaRequest: (learningProviderId: string) => dispatch(refreshMediaRequest(learningProviderId)),
  getMediaSetsByLearningProviderCode: (learningProviderCode: string) =>
  dispatch(getMediaSetsByLearningProviderCode(learningProviderCode)),
});

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(SampleMediaRequests);
