import { Reducer } from 'redux';

import * as types from '../../../actions/action-types';
import initialState from '../../../reducers/initialState';
import { GlobalAlertListActions } from './global-alert-list.actions';
import { GlobalAlertMessage } from '../../../models/global-alert-message';

export const globalAlertReducer: Reducer<GlobalAlertMessage[] | null> = (
    state: GlobalAlertMessage[] | null = initialState.globalAlertMessages,
    action: GlobalAlertListActions
  ) => {

    if (!state) {
        return state;
    }
    
    switch (action.type) {
        case types.ADD_GLOBAL_ALERT:
            const newMesages = state.filter((m: GlobalAlertMessage) => m !== action.status);
            newMesages.push(action.status);
            return newMesages;
        case types.REMOVE_GLOBAL_ALERT:
            const messageRemoved = state.filter((m: GlobalAlertMessage) => m !== action.status);
            return messageRemoved;
        default:
            return state;
    }
  };