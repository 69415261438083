import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import _ from "lodash";

import { GlobalAlertMessage } from "../../models/global-alert-message";
import alertImage from "./warning-24.svg";
import GlobalAlertListRow from "./global-alert-lisr-row";

import {Button} from 'ukas.uux.react-components';

require("@webcomponents/webcomponentsjs/webcomponents-bundle");
require("@webcomponents/webcomponentsjs/custom-elements-es5-adapter");
require("@pearson-ux/drawer/js/dist/pearson-drawer");

export interface StateProps {
    globalAlertMessages: GlobalAlertMessage[] | null;
}

export interface DispatchProps {
    removeAlert: (globalAlertMessage: GlobalAlertMessage) => void;
}

export type OwnProps = RouteComponentProps<{}>;

type Props = OwnProps & StateProps & DispatchProps;

interface LocalState {
    drawerIsOpen: boolean | undefined;
    drawerText: any;
}

export class GlobalAlertList extends React.Component<Props, LocalState> {
    constructor(props: Props) {            
      super(props);
      this.state = {
        drawerIsOpen: undefined,
        drawerText: {
            headerTitle: "Alert",
            closeButtonSRText: "Close",
            backButtonText: "Back"
        }
      }
      this.showAlerts = this.showAlerts.bind(this);
      this.cancelAlert = this.cancelAlert.bind(this);
    }

    render() {
        return (
          <div>
            {this.createAlertButton()}
            <pearson-drawer
              id="pearsonDrawer"
              open={this.state.drawerIsOpen}
              drawerIsOpen={this.state.drawerIsOpen}>
              <h3 slot="title" className="title">
                Alerts
              </h3>    
              <div slot="content" className="content">       
                {this.props.globalAlertMessages && this.props.globalAlertMessages.length > 0 &&
                  <div>
                    <Button
                      id="cancelAllAlerts"
                      onClick={this.cancelAllAlerts.bind(this, this.props.globalAlertMessages)}
                      variant="primary">Dismiss all alerts
                    </Button>
                    <br/><br/>
                  </div>    
                }       
                {this.createAlertRows()}
              </div>
            </pearson-drawer>
          </div>
        );
    }


    createAlertRows() {
        if (this.props.globalAlertMessages && this.props.globalAlertMessages.length > 0 ) {
          const rows: any[] = [];
    
          this.props.globalAlertMessages.forEach((alert, i) => {
            rows.push(
              <div key={"progress" + i}>
                <GlobalAlertListRow alert={alert} cancelAlert={this.cancelAlert} />                
              </div>
            );
          });
    
          return rows;
        }
        return <div>No current alerts.</div>;
      }

    createAlertButton() {
        const alertDivStyle: React.CSSProperties = {
            display: "block",
            position: "fixed",
            height: "70px",
            width: "70px",
            right: "0",
            top: "295px",
            bottom: "0",
            backgroundColor: "#f5f5f5",
            padding: "5px"
        };

        if (
            this.props.globalAlertMessages &&
            this.props.globalAlertMessages.length > 0
        ) {
            return (
            <div
                onClick={this.showAlerts}
                style={alertDivStyle}>
                <img src={alertImage} width="70px" />
            </div>
            );
        }
        return <div>&nbsp;</div>;
    }

    cancelAlert(globalAlertMessage: GlobalAlertMessage) {
        this.props.removeAlert(globalAlertMessage);
        return;
    }

    cancelAllAlerts = (alerts: GlobalAlertMessage[]) => {
      alerts.forEach((alert, i) => {
        this.cancelAlert(alert);
      });
    };

    showAlerts = () => {
        this.setState({ drawerIsOpen: !this.state.drawerIsOpen });
    };
    
}

export default GlobalAlertList;