import { LearnerUnit } from "./LearnerUnit";

export class MediaRequest {
  consortiaId: number; 
  academicYear: string;
  mediaSetId: string='';
  learningProviderId: string='';
  learningProviderName: string='';
  learningProviderCode: string='';
  standardsVerifierId: string='';
  standardsVerifierName: string='';
  qualificationGroupCode: string='';
  qualificationId: string='';
  submittedBy: string='';
  qualificationName: string='';
  deadlineDateTime: Date;
  purpose: string='';
  samplesPerLearner: number=0;
  learnerUnits: LearnerUnit[];
  numberOfLearners: number=0;
  numberOfFiles: number=0;
  mediaSampleType: string = 'NONE';
  status: string;
  files: MediaRequestFile[]
  submissionDate: Date;
  version: string='';
  proxyName: string= '';
  parentRequests: string[];
  parentLearningProviderCode: string;

  constructor() {
    const dt = new Date().toDateString();
    this.learnerUnits = [];
    this.files =[];
    this.parentRequests = [];
    // this.deadlineDateTime = new Date(dt + " 00:00:00");
    this.submissionDate = new Date(dt + " 00:00:00");
  }
}

export class MediaRequestFile {
  fileId: string='';
  unitId: string='';
  learnerIds: string[];
  fileName: string='';
  fileStatus: string='';
  fileSize?: string='';
  isUploadByFile?: boolean;
  fileSizeBytes?: number;
  learnerCount?: number;
  unitName?: string;
  createdDateTime: Date = new Date();
  rejectedReason?: string;
  rejectedFiles: [] ;  

  constructor() {
    this.learnerIds = [];
  }
}

export class CreateMediaRequestFile {
  fileId: string='';
  unitId: string='';
  learningProviderId: string='';
  mediaSetId: string='';
  learnerIds: string[];
  fileKey: string='';
  fileName: string='';

  constructor() {
    this.learnerIds = [];
  }
}

