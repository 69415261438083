import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { GlobalAlertList, StateProps, DispatchProps } from "./global-alert-list.component";
import { StoreState } from "../../store/store-state";
import _ from "lodash";
import { GlobalAlertListActions, removeGlobalAlertAction } from "./shared/global-alert-list.actions";
import { GlobalAlertMessage } from "../../models/global-alert-message";

const mapStateToProps = (state: StoreState): StateProps => {  
  return {globalAlertMessages: state.globalAlertMessages}
};

const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, void, GlobalAlertListActions>
): DispatchProps => ({
    removeAlert: (globalAlertMessage: GlobalAlertMessage) => dispatch(removeGlobalAlertAction(globalAlertMessage))
});  
  
export default connect<StateProps>(
  mapStateToProps,
  mapDispatchToProps
)(GlobalAlertList);

