import * as React from 'react';
import { GlobalAlertMessage } from "../../models/global-alert-message";
import {Button} from 'ukas.uux.react-components';

interface GlobalAlertListRowProps {
  alert: GlobalAlertMessage;
  cancelAlert: (alert: GlobalAlertMessage) => void;
}
const GlobalAlertListRow = ({
    alert,
    cancelAlert
}: GlobalAlertListRowProps) => {

  const cancel = () => {
    return cancelAlert(alert);
  };
 
  return (
    <div
    style={{
        borderStyle: "solid",
        borderWidth: "2px",
        borderColor: "grey",
        padding: "15px",
        marginBottom: "5px"
    }}>
        <span>
            {alert.title}
        </span>
        <br />
        <span>
            {alert.message}
        </span>
        <div>&nbsp;</div>
        <div>
            <Button
            id="cancelAlert"
            onClick={cancel}
            variant="secondary">
            Dismiss
            </Button>
        </div>
    </div>
  );
};

export default GlobalAlertListRow;
