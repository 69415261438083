import { Reducer } from 'redux';

import * as types from '../actions/action-types';
import { AssessmentAssociateAction } from '../actions/assessment-associate.actions';
import { AssessmentAssociate } from '../models/assessment-associate';
import initialState from './initialState';


export const AssessmentAssociateReducer: Reducer<AssessmentAssociate | null> = (
  state: AssessmentAssociate | null = initialState.assessmentAssociate,
  action: AssessmentAssociateAction
) => {
  if (action.type === types.GET_ASESSMENT_ASSOCIATE_SUCCESS) {
    return action.assessmentAssociate;
  }else{
    return state;
  }
  // switch (action.type) {
  //   case types.GET_ASESSMENT_ASSOCIATE_SUCCESS:
  //     return action.assessmentAssociate;
  //   default:
  //     return state;
  // }
};