import * as React from "react";
import _ from "lodash";
import { MediaRequestFile } from "../../../../models/media-request";
import { LearnerUnit } from "../../../../models/LearnerUnit";
import Dropzone from "react-dropzone";
import uploadingImage from "../upload-24.svg";
import { FormattedDate, FormattedTime } from "react-intl";
import TextHelper from "../../shared/text-formatting";
import moment from "moment";
import * as avatsFileStatus from "../../shared/constants";
// import * as requestStatus from "../../shared/constants";
import ThreeDotsMenu from "../../shared/three-dots-menu"
import {fileStatus, requestStatusEnums as requestStatus} from '../../../sample-media-request/shared/enum';
import { MediaRequest } from '../../../../models/media-request';
import { Table, Tooltip } from 'ukas.uux.react-components';
import infoFillImage from "../info-fill-18.svg";
import { userLoginStateHook } from "../../../../shared/context-api/hooks";
import { HasWriteAccess } from "../../../../components/common/user-validation";


interface Props {
  sampleMediaRequestLearners: LearnerUnit[];
  mediaRequestFiles: MediaRequestFile[];
  units: Array<{ unitId: string; unitName: string }>;
  onSelectUnit: (event: any) => void;
  onUBFRemoveFile: (
    removeSelectedFile: MediaRequestFile,
  ) => void;
  goBack: (event: Event) => void;
  canSubmit: () => boolean;
  submit: (event: Event) => void;
  allowUpload: boolean;
  showFiles: (
    learnerUnit: LearnerUnit,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  uploadFileEvent: (file: File[], unitId: string, learnerId: string) => void;
  unitFilterLabel: string;
  onSort: (item: string) => void;
  getIconName: (columnName: string) => string;
  onLoadSort: boolean;
  isConsortia: boolean;
  showLearners: (
    showLearners: MediaRequestFile,
  ) => void;
  sampleMediaRequest: MediaRequest | null;  
  userLoginstate:any;
  // isBusy: boolean;
  // onRemoveFile: (
  //   file: MediaRequestFile,
  //   learnerUnit: LearnerUnit,
  //   event: React.UIEvent<HTMLSpanElement>
  // ) => void;
  // canRemoveFiles: boolean;
}
interface State {
  selectedColumn: string;
  direction: string;
  searchText: string;
}

export class SampleMediaRequestsFilesTab extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedColumn: "unitName",
      direction: "up",
      searchText: ""
    };
  }

  render() {
    const {
      sampleMediaRequestLearners,
      mediaRequestFiles,
      uploadFileEvent,
      onLoadSort,
      // onSort,
      // getIconName,
      units,
      unitFilterLabel,
      sampleMediaRequest

    } = this.props;
    const { selectedColumn, direction, searchText } = this.state;

    const sortedSampleMediaRequestLearners = onLoadSort
      ? _.orderBy(sampleMediaRequestLearners, ["unitName", "fileName"])
      : sampleMediaRequestLearners;
    const onDrop = (files: File[]) => {
      return uploadFileEvent(
        files,
        sortedSampleMediaRequestLearners[0].unitId,
        ""
      );
    };
    const selectedUnit = units.filter(u => u.unitName === unitFilterLabel);

    let uploadedByFilesList = this.filterUploadByFiles(
      units,
      selectedUnit,
      mediaRequestFiles
    );
    uploadedByFilesList = _.orderBy(uploadedByFilesList, [
      "unitName", 
      (file) => file.fileName.toLowerCase()
    ])
    uploadedByFilesList =
      selectedColumn === ""
        ? this.filter(uploadedByFilesList, searchText)
        : this.sort(uploadedByFilesList, selectedColumn, direction);
    // console.log("uploadedByFilesList", uploadedByFilesList);
    const sampleMediaRequestStatus = sampleMediaRequest && sampleMediaRequest.status ? sampleMediaRequest.status : "";

    const submitAllowed = this.props.userLoginstate;
    return (
      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        {           
          !this.checkRequestStatus(sampleMediaRequestStatus)  &&
        <span className="pe-label--bold" style={{ marginRight: "10px" }}>
          Select a unit to upload the required file(s).
        </span>
        }
        {unitFilterLabel !== "All Units" && !this.checkRequestStatus(sampleMediaRequestStatus) && (
          <span style={{ marginLeft: "5px" }}>
            <Dropzone onDrop={onDrop}>
              {({ getRootProps, getInputProps, isDragActive }) => {
                return (
                  <div
                    {...getRootProps()}
                    className={
                      "dragzone uploadFileIcon " + (isDragActive ? " isDragActive" : "" + (HasWriteAccess(submitAllowed) ? '' : 'dragzone-disabled'))
                    }
                  >
                    <input {...getInputProps()} disabled={!HasWriteAccess(submitAllowed)}/>
                    {isDragActive ? (
                      // <p>Drop file here...</p>
                      <p>
                        <span className="tooltipdrop">
                          Drag &amp; drop file(s) here or click to select
                          file(s) to upload.
                        </span>
                      
                        <img
                          src={uploadingImage}
                          width="30px"
                          className="color uploadImage"
                        />
                      </p>
                    ) : (
                      // <p>Drag &amp; drop a file here, or click to select a file to upload.</p>
                      <p className="tooltipInit">
                        <span className="tooltipdropInit">
                          Drag &amp; drop file(s) here or click to select
                          file(s) to upload.
                        </span>
                        <img
                          src={uploadingImage}
                          width="30" height="30"
                        />
                      </p>
                    )}
                  </div>
                );
              }}
            </Dropzone>
          </span>
        )}
        <div>
          <Table responsive="xl">
            <caption>&nbsp;</caption>
            <thead>
              <tr key="0">
                <td>File Name</td>
                <td>Upload Details</td>
                <td>Unit</td>
                <td>File Status</td>
                <td>No. Of Linked Learners</td>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!uploadedByFilesList.length && (
                <tr>
                  <td
                    colSpan={7}
                    text-align="center"
                    className="no-hover greytext"
                  >
                    {
                      "No files found. Uploaded file details will be displayed here"
                    }
                  </td>
                </tr>
              )}
              {uploadedByFilesList.length
                ? uploadedByFilesList.map((f: MediaRequestFile, index) => (
                    <tr className="no-hover">
                      <td>
                        {f.fileName}
                        <br />
                        <span style={{ color: "grey", fontSize: "12px" }}>
                          {f.fileSize ? f.fileSize + "" : ""}
                        </span>
                      </td>
                      {/* <td>{f.fileSize ? f.fileSize + "" : "NA"}</td> */}
                      <td>
                        <FormattedDate
                          value={f.createdDateTime}
                          year="numeric"
                          month="numeric"
                          day="numeric"
                        />
                        <br />
                        <FormattedTime value={f.createdDateTime} />
                      </td>

                      <td>{f.unitName}</td>

                      <td>
                        {f.fileStatus === fileStatus.PREPARING && <span>Scanning</span>}
                        {f.fileStatus === fileStatus.READY && <span>Accepted</span>}
                        {f.fileStatus === fileStatus.REJECTED && ( 
                          <span>
                            <Tooltip
                              id="tooltip-top"
                              placement="top"
                              maxWidth="400px"
                              value={this.getRejectedReason(
                                f.rejectedReason + "",
                                f.fileName,
                                f.rejectedFiles.length
                              )}
                            >
                              <img src={infoFillImage} />
                            </Tooltip>
                            &nbsp;Rejected
                          </span>
                        )}
                      </td>

                      <td>
                        {f.learnerIds.length ? f.learnerIds.length : "0"}
                        {" Learners Linked"}
                      </td>
{/* 
                      <td>
                        <a href="#" onClick={showLearners.bind(event, f)}>
                          Link Learner(s)
                        </a>{" "}
                      </td> */}

                      <td>
                        <ThreeDotsMenu 
                          mediaRequestFile={f}
                          render={this.menuItems(
                            this.checkRequestStatus(sampleMediaRequestStatus), 
                            f
                          )}
                        />
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }

  menuItems = ( 
  disableRemoveFileLink:boolean,
  f: MediaRequestFile) =>
  ()=>{
 const submitAllowed = this.props.userLoginstate;
  const disablesubmit = HasWriteAccess(submitAllowed) ? "" : "isDisabled";
     return (
       <ul>
         <li>{
              (f.fileStatus === fileStatus.REJECTED)?
              (<span className="disabled-link">Link Learners</span>) :
              (<a href="javascript:void(0)" onClick={()=>this.props.showLearners(f)}>Link Learners</a>)
          }</li>           
         <li>{
              ( f.fileStatus === fileStatus.PREPARING || disableRemoveFileLink ) ?
              (<span className="disabled-link">Remove File</span>) :              
              (<a href="javascript:void(0)" className={disablesubmit} onClick={()=>this.props.onUBFRemoveFile(f)}>Remove File</a>)
          }</li>
       </ul>
    )
  
  }

  checkRequestStatus = (sampleMediaRequestStatus:string):boolean => {
    // The following statuses of a request will decide whether upload icon, remove file, 
    // link learners checkboxes incl select all will be displayed
    const requestStatusProhibited =  [requestStatus.SUBMIT_PENDING, requestStatus.UPLOAD_SUBMITTED];
    return _.includes(requestStatusProhibited,sampleMediaRequestStatus)
  }

  unitByUnitId = (
    units: Array<{ unitId: string; unitName: string }>,
    unitId: string
  ) => units.filter(u => u.unitId === unitId);

  sizeInBytes = (filesizeStr: string) => {
    const str = filesizeStr;
    if (!str) {
      return 0;
    }
    const sizeStr = str.replace(/[0-9\. ]/g, "").toUpperCase();
    const size = parseFloat(str.replace(/[a-zA-Z ]/g, ""));
    let fileSizeNum = 0;
    switch (sizeStr) {
      case "BYTES":
        fileSizeNum = size;
        break;
      case "KB":
        fileSizeNum = Math.floor(size * Math.pow(1024, 1));
        break;
      case "MB":
        fileSizeNum = Math.floor(size * Math.pow(1024, 2));
        break;
      case "GB":
        fileSizeNum = Math.floor(size * Math.pow(1024, 3));
        break;
    }
    return fileSizeNum;
  };

  filterUploadByFiles = (
    units: Array<{ unitId: string; unitName: string }>,
    selectedUnit: Array<{ unitId: string; unitName: string }>,
    mediaRequestFiles: MediaRequestFile[]
  ) => {
    // console.log("filterUploadByFiles units", units);
    // console.log("filterUploadByFiles selectedUnit", selectedUnit);
    // console.log("filterUploadByFiles mediaRequestFiles", mediaRequestFiles);

    const filteredFilesWithBytes = mediaRequestFiles.map(elem => {
      const unitName = selectedUnit.length
        ? selectedUnit[0].unitName
        : this.unitByUnitId(units, elem.unitId)[0].unitName;
      const learnerCount = elem.learnerIds.length ? elem.learnerIds.length : 0;
      // console.log("learnerCount", learnerCount);
      return elem && elem.fileSize
        ? {
            ...elem,
            unitName,
            learnerCount,
            fileSizeBytes: this.sizeInBytes(elem.fileSize)
          }
        : { ...elem, unitName, learnerCount, fileSizeBytes: 0 };
    });
    mediaRequestFiles = filteredFilesWithBytes.filter(
      x => x !== undefined
    ) as MediaRequestFile[];
    // console.log("mediaRequestFiles", mediaRequestFiles);
    let upldFiles;
    if (!selectedUnit.length) {
      upldFiles = mediaRequestFiles.filter(f => f.isUploadByFile === true);
    } else {
      upldFiles = mediaRequestFiles.filter(
        f => f.isUploadByFile === true && f.unitId === selectedUnit[0].unitId
      );
    }
    // console.log("upldFiles", upldFiles);
    return upldFiles;
    // return (!selectedUnit.length?
    //   mediaRequestFiles.filter( f => f.isUploadByFile === true) :
    //   mediaRequestFiles.filter( f => f.isUploadByFile === true && f.unitId === selectedUnit[0].unitId) )
  };

  getRejectedReason = (
    responseRejectedReason: string,
    fileName: string,
    fileCount: number
  ): string => {
    switch (responseRejectedReason.toUpperCase()) {
      case avatsFileStatus.AVATS_STATUS_INFECTED:
        return fileName.includes(".zip") || fileName.includes(".rar")
          ? avatsFileStatus.AVATS_STATUS_INFECTED_ZIP_TEXT.replace(
              "[X]",
              fileCount.toString()
            )
          : avatsFileStatus.AVATS_STATUS_INFECTED_TEXT;
      case avatsFileStatus.AVATS_STATUS_UNSUPPORTED:
        return avatsFileStatus.AVATS_STATUS_UNSUPPORTED_TEXT;
      case avatsFileStatus.AVATS_STATUS_PASSWORD_PROTECTED:
        return fileName.includes(".zip") || fileName.includes(".rar") 
        ? avatsFileStatus.AVATS_STATUS_PASSWORD_PROTECTED_TEXT
        : avatsFileStatus.AVATS_STATUS_PASSWORD_PROTECTED_TEXT_SINGLE;
      default:
        return avatsFileStatus.AVATS_STATUS_DEFAULT_TEXT;
    }
  };

  getIconName = (
    selectedCol: string,
    selectedColumn: string,
    direction: string
  ) => {
    if (selectedColumn === selectedCol) {
      return direction === "up" ? "sort-up-18" : "sort-down-18";
    }

    return "sortable-18";
  };

  handleColumnSort = (
    selectedCol: string,
    direction: string,
    selectedColumn: string
  ) => {
    return () => {
      let dir = "down";

      if (selectedColumn === selectedCol) {
        dir = direction === "up" ? "down" : "up";
      }

      this.setState({ direction: dir });
      this.setState({ selectedColumn: selectedCol });
    };
  };

  filter = (
    files: MediaRequestFile[],
    searchText: string
  ): MediaRequestFile[] => {
    const searchTerm = searchText;
    if (searchTerm !== "" && searchTerm.length >= 3) {
      const mediaRequests = JSON.parse(JSON.stringify(files));
      const columnNames = [
        "fileName",
        "unitName",
        "createdDateTime",
        "fileStatus"
      ];
      return _.filter(mediaRequests, mediaRequest => {
        mediaRequest.status = TextHelper.titleCaseUploadStatus(
          mediaRequest.status
        );
        mediaRequest.deadlineDateTime = moment(
          new Date(mediaRequest.deadlineDateTime)
        ).format("DD MMM YYYY");
        return _(mediaRequest)
          .pick(columnNames)
          .some(searchstring => {
            return _(searchstring)
              .toLower()
              .includes(searchTerm);
          });
      }) as MediaRequestFile[];
    }
    return files;
  };

  sort = (
    files: MediaRequestFile[],
    selectedColumn: string,
    direction: string
  ): MediaRequestFile[] => {
    const lodashDirection = direction === "down" ? "desc" : "asc";
    const sortedSampleMediaRequestFiles = _.orderBy(
      files,
      [
        mediaRequestFile => {
          // Added this condition to counteract page crash if
          // field has no value
          if (!mediaRequestFile[selectedColumn]) {
            return null;
          }

          if (
            typeof mediaRequestFile[selectedColumn].toLowerCase === "function"
          ) {
            return mediaRequestFile[selectedColumn].toLowerCase();
          } else {
            return mediaRequestFile[selectedColumn];
          }
        }
      ],
      [lodashDirection]
    ) as MediaRequestFile[];
    return this.filter(sortedSampleMediaRequestFiles, "");
  };
}
export default userLoginStateHook(SampleMediaRequestsFilesTab);

