import { Reducer } from 'redux';

import * as types from '../actions/action-types';
import { MediaUploadActions } from '../actions/media-upload.actions';
import initialState from './initialState';
import { MediaUploadStatus } from '../models/media-upload';

export const mediaUploadReducer: Reducer<MediaUploadStatus[] | null> = (
    state: MediaUploadStatus[] | null = initialState.mediaUploadStatus,
    action: MediaUploadActions
  ) => {

    if (!state) {
        return state;
    }
    
    switch (action.type) {
        case types.UPLOAD_MEDIA_PROGRESS:
            const newStatus = state.filter((m: MediaUploadStatus) => m.fileKey !== action.status.fileKey);
            newStatus.push(action.status);
            return newStatus;
        case types.UPLOAD_MEDIA_SUCCESS:
        case types.UPLOAD_MEDIA_ERROR:
        case types.UPLOAD_MEDIA_CANCELLED:
            const statusRemoved = state.filter((m: MediaUploadStatus) => m.fileKey !== action.status.fileKey);
            return statusRemoved;
        default:
            return state;
    }
  };