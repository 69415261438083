import axios from 'axios';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { MediaRequest } from '../models/media-request';

import * as action_types from './action-types';
import {
  ajaxCallError,
  beginAjaxCall,
  AjaxCallActions
} from './ajax-status.actions';

import config from '../app-config';
import { StoreState } from '../store/store-state';

 import Auth from './auth/auth';
 const auth = new Auth();

interface GetSampleMediaRequestsSuccessAction extends Action {
  type: action_types.GET_SAMPLE_MEDIA_REQUESTS_SUCCESS;
  sampleMediaRequests: MediaRequest[];
}

export type getSampleMediaRquestsSuccessAction =
  | GetSampleMediaRequestsSuccessAction
  | AjaxCallActions;

export const getSampleMediaRquestsSuccessAction = (
  sampleMediaRequests: MediaRequest[]
): GetSampleMediaRequestsSuccessAction => ({
  sampleMediaRequests,
  type: action_types.GET_SAMPLE_MEDIA_REQUESTS_SUCCESS
});

export const getMediaSetsByLearningProviderCode = (learningProviderCode: string) => {
 const headers = { 'Authorization': `Bearer ${auth.getAccessToken()}`, 'x-id-token': `${auth.getIdToken()}`}
  return (
    dispatch: ThunkDispatch<StoreState, void, getSampleMediaRquestsSuccessAction>
  ) => {
    dispatch(beginAjaxCall());
    return axios
      .get(`${config.API_GATEWAY.URL}/sample-media-requests/sample-media-requests-by-learning-proder-code/${learningProviderCode}`,
        {
          headers,
        })
      .then(response => {
        const sampleMediaRequests = response.data;
        dispatch(
          getSampleMediaRquestsSuccessAction(
            sampleMediaRequests.map((sampleMediaRequest: MediaRequest) => {
              const mr = new MediaRequest();
              mr.consortiaId = sampleMediaRequest.consortiaId;
              mr.parentRequests = sampleMediaRequest.parentRequests;
              mr.academicYear = sampleMediaRequest.academicYear;
              mr.mediaSetId = sampleMediaRequest.mediaSetId;
              mr.qualificationId = sampleMediaRequest.qualificationId;
              mr.qualificationName = sampleMediaRequest.qualificationName;
              mr.learningProviderId = sampleMediaRequest.learningProviderId;
              mr.learningProviderName = sampleMediaRequest.learningProviderName;
              mr.learningProviderCode = sampleMediaRequest.learningProviderCode;
              if(sampleMediaRequest.deadlineDateTime !== null){
                mr.deadlineDateTime = new Date(sampleMediaRequest.deadlineDateTime);
              }
              mr.numberOfLearners = sampleMediaRequest.numberOfLearners;
              mr.numberOfFiles = sampleMediaRequest.numberOfFiles;
              mr.parentLearningProviderCode=sampleMediaRequest.parentLearningProviderCode;
              mr.submittedBy = sampleMediaRequest.submittedBy;
              mr.qualificationGroupCode= sampleMediaRequest.qualificationGroupCode;
              mr.standardsVerifierId = sampleMediaRequest.standardsVerifierId;
              mr.standardsVerifierName = sampleMediaRequest.standardsVerifierName;
              mr.learnerUnits = sampleMediaRequest.learnerUnits;
              mr.files = sampleMediaRequest.files;
              mr.status = sampleMediaRequest.status;
              mr.submissionDate = new Date(sampleMediaRequest.submissionDate);
              mr.version = sampleMediaRequest.version;
              mr.proxyName = sampleMediaRequest.learningProviderCode+ " "+ sampleMediaRequest.learningProviderName;
              localStorage.setItem('proxyName', sampleMediaRequest.learningProviderCode+ " "+ sampleMediaRequest.learningProviderName);
              localStorage.setItem('prevProxyId', sampleMediaRequest.learningProviderCode);
              return mr;
            })
          )
        );
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};
