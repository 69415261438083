import * as React from 'react';
import { MediaRequest, MediaRequestFile } from '../../../../models/media-request';
import { FormattedDate } from 'react-intl';

import TextHelper from '../../shared/text-formatting';
import infoFillImage from "../../sample-media-request-learners/info-fill-18.svg";

import * as avatsFileStatus from '../../shared/constants';
import { Tooltip } from 'ukas.uux.react-components';

interface SampleMediaRequestListRowProps {
  sampleMediaRequest: MediaRequest;
  onSelect: (mediaRequestId: string, event: Event) => void;
}
const getRejectedReason = (responseRejectedReason: MediaRequestFile[], isUploadReject: boolean) =>{
  let rejectedReason = "";
  if(isUploadReject){
    if(responseRejectedReason.some(reject => reject.fileStatus === avatsFileStatus.AVATS_STATUS_REJECTED)){
      rejectedReason =  avatsFileStatus.AVATS_STATUS_REJECTED_TEXT;
    } 
    else {
      rejectedReason =  avatsFileStatus.STANDARDS_VERIFIER_REJECT_TEXT;
    }
  } else {
    if(responseRejectedReason.some(reject => reject.fileStatus === avatsFileStatus.AVATS_STATUS_REJECTED)){
      rejectedReason =  avatsFileStatus.AVATS_STATUS_REJECTED_TEXT;
    } 
  }
  return rejectedReason;
}
const SampleMediaRequestsListRow = ({
  sampleMediaRequest, onSelect
}: SampleMediaRequestListRowProps) => {
  const uploadStatus = sampleMediaRequest.status.includes('_') ? TextHelper.titleCaseUploadStatus(sampleMediaRequest.status) : sampleMediaRequest.status;
  const uploadRejectToolTip = getRejectedReason(sampleMediaRequest && sampleMediaRequest.files, false)
// 
  return (
    <tr onClick={uploadStatus !== "Request Recalled" && onSelect.bind(event, sampleMediaRequest.mediaSetId)}>
      <td>{sampleMediaRequest.qualificationGroupCode}</td>
      <td>{sampleMediaRequest.qualificationName}
      &nbsp;<b>{sampleMediaRequest.consortiaId ? '(C)': '' }</b>
      &nbsp;
      <b className="second-sample-text-color">{sampleMediaRequest.parentRequests && sampleMediaRequest.parentRequests.length > 0 ? "("+(sampleMediaRequest.parentRequests.length + 1)+")" : ""}</b>
      </td>
      <td>{sampleMediaRequest.standardsVerifierId}</td>
      <td>{sampleMediaRequest.standardsVerifierName}</td>
      <td>{sampleMediaRequest.numberOfLearners}</td>
      <td>{(sampleMediaRequest.deadlineDateTime && sampleMediaRequest.deadlineDateTime !== undefined && sampleMediaRequest.deadlineDateTime !== null) && (
          <FormattedDate
            value={sampleMediaRequest.deadlineDateTime}
            year="numeric"
            month="short"
            day="numeric"
          />)}
      </td>
      <td>
        {/* Status Upload Not Submitted */}
        {uploadStatus === 'Upload Not Submitted' && (
          <Tooltip
            id="tooltip-top"
            placement="top"
            maxWidth="400px"
            value="The sample has not been submitted to the Standards Verifier because a number of files were rejected.  Please make the necessary amendments and resubmit it as soon as possible."
          >
          <span className="tooltip-top"><img src={infoFillImage} /></span>
          </Tooltip>
        )}

       {/* Status Upload Returned */}
       { uploadStatus === 'Upload Returned' && (
          <Tooltip
            id="tooltip-top"
            placement="top"
            maxWidth="400px"
            value={getRejectedReason(sampleMediaRequest && sampleMediaRequest.files, true)}
          >
          <span className="tooltip-top"><img src={infoFillImage} /></span>
          </Tooltip>
       )}

        {/* Status Request Recalled */}
        {uploadStatus === 'Request Recalled' && (
          <Tooltip
            id="tooltip-top"
            placement="top"
            maxWidth="400px"
            value="The Standards Verifier has recalled this sample request.  Until it is submitted back to your learning provider you cannot manage this sample request."
          >
            <span className="tooltip-top"><img src={infoFillImage} /></span>
          </Tooltip>
        )}

        {/* Status Upload Rejected  */}
        {uploadRejectToolTip 
          && uploadStatus !== 'Upload Returned' 
          && uploadStatus !== 'Upload Not Submitted' 
          && (
            <Tooltip
              id="tooltip-top"
              placement="top"
              maxWidth="400px"
              value={uploadRejectToolTip}
            >
              <span className="tooltip-top"><img src={infoFillImage} /></span>
            </Tooltip>
          )
        }

        {/* Value */}
        {uploadStatus}
        </td>




    </tr>
  );
};

export default SampleMediaRequestsListRow;
