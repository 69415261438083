import { Reducer } from 'redux';

import { AuthAction } from '../actions/auth/auth.actions';
import { Auth } from '../models/auth';
import initialState from './initialState';

import {
  AUTH_USER,
  UNAUTH_USER,
  AUTH_IN_PROGRESS,
  AUTH_ERROR
} from '../actions/action-types';

export const AuthReducer: Reducer<Auth> = (
  state: Auth = initialState.auth,
  action: AuthAction
) => {
  switch (action.type) {
    // case CLEARDOWN:
    //   return { ...state, ...INIT };
    case AUTH_USER:
      return { ...state, ...initialState.auth, authenticated: true, profile: action.profile };
    case AUTH_IN_PROGRESS:
      return { ...state, ...initialState.auth, loading: true };
    case UNAUTH_USER:
      return { ...state, ...initialState.auth };
    // case FORGOT_SUCCESS:
    //   return { ...state, ...INIT, forgotMsg: action.message };
    case AUTH_ERROR:
      return {
        ...state,
        ...initialState.auth,
        error: action.error,
        timestamp: action.timestamp
      };
    default:
      return state;
  }
};
