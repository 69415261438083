import React from "react";
import { UserAction, UserLoginDataType } from "./actions";

export const userDataState = React.createContext<UserLoginDataType | undefined>(
  undefined
);

export const userDataDispatch = React.createContext<
  React.Dispatch<UserAction> | undefined
>(undefined);

// import {InitialStateType, initialState} from './reducers';

// const AppContext = React.createContext<{
//     state:InitialStateType;
//     dispatch: React.Dispatch<any>;
// }>({
//     state:initialState,
//     dispatch:() => null
// });

// const AppContext = React.createContext<InitialStateType>(initialState)

// export default AppContext;
