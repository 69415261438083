import { Reducer } from 'redux';

import * as types from '../actions/action-types';
import { UserDetailsAction } from '../actions/user-details-by-forgerock.actions';
import { User } from '../models/user-details-forgerock-request';
import initialState from './initialState';


export const userDetailsByForgerockRequestReducer: Reducer<User > = (
    state: User = initialState.userDetailsByForgerock,
    action: UserDetailsAction
  ) => {

    if(action.type  === types.GET_USER_DETAILS_BY_FORGEROCK_REQUESTS_SUCCESS){
      return action.userDetailsByForgerock;
    }else{
      return state;
    }
    // switch (action.type) {
    //     case types.GET_USER_DETAILS_BY_FORGEROCK_REQUESTS_SUCCESS:
    //       return action.userDetailsByForgerock;
    //     default:
    //         return state;
    // };
  };
  
  export function ValidateUserPermissionsReducer(
    state: boolean = initialState.userPermissionValidated,
    action: UserDetailsAction
  ): boolean {
    if(action.type  === types.VALIDATE_USER_PERMISSIONS_SUCCESS){
      return action.userPermissionValidated;
    }else{
      return state;
    }
    // switch (action.type) {    
    //   case types.VALIDATE_USER_PERMISSIONS_SUCCESS:
    //       return action.userPermissionValidated;
    //   default:
    //     return state;
    // }
  }

  export function GetEnvReducer(
    state: boolean = initialState.showCreateMenu,
    action: UserDetailsAction
  ): boolean {
    if(action.type  === types.GET_ENV_SUCCESS){
      return action.e;
    }else{
      return state;
    }
    // switch (action.type) {    
    //   case types.GET_ENV_SUCCESS:
    //       return action.e;
    //   default:
    //     return state;
    // }
  }

  export function CheckSessionReducer(
    state: boolean = initialState.isSessionAlive,
    action: UserDetailsAction
  ): boolean {
    switch (action.type) {    
      case types.SESSION_ALIVE_FAILURE:
        case types.SESSION_ALIVE_SUCCESS:  
          return action.isSessionAlive;
      default:
        return state;
    }
  }