import * as React from 'react';
import {Button, TextInput} from 'ukas.uux.react-components';
interface AssessmentAssociateAvailabilitySearchProps {
  isBusy: boolean;
  aaNumber: string;
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onSearch: (event: any) => void;
}

const AssessmentAssociateAvailabilitySearch = ({
  isBusy,
  aaNumber,
  onChange,
  onSearch
}: AssessmentAssociateAvailabilitySearchProps) => {

  return (
    <form className="pe-form">
      <p className="pe-form--desc">Please enter a valid Assessement Associate number to begin.</p>
      <TextInput
        id="aaNumber"
        label={'Assessement Associate Number'}
        onChange={onChange}
        value={aaNumber}
        maxLength={10}
      />
      <br />
      <div>
        <div className="pull-right">
          <Button
            variant="primary"
            size="lg"
            children="Search"
            disabled={isBusy || aaNumber.length === 0}
            onClick={(e)=>onSearch(e)}
          />
        </div>
        <div className="clearfix" />
      </div>
    </form>
  );
};

export default AssessmentAssociateAvailabilitySearch;
