import axios from "axios";
import { Action } from "redux";
import { ThunkDispatch, ThunkAction } from "redux-thunk";
import {
  User
} from "../models/user-details-forgerock-request";
import _ from "lodash";
import * as action_types from "./action-types";
import {
  ajaxCallError,
  beginAjaxCall,
  AjaxCallActions
} from "./ajax-status.actions";

import config from "../app-config";
import { StoreState } from "../store/store-state";
import { userTypeEnums } from "../features/sample-media-request/shared/enum";
import Auth from "./auth/auth";

const auth = new Auth();

interface SessionAliveFailureAction extends Action {
  isSessionAlive: boolean;
  type: action_types.SESSION_ALIVE_FAILURE;
}

interface SessionAliveSuccessAction extends Action {
  isSessionAlive: boolean;
  type: action_types.SESSION_ALIVE_SUCCESS;
}
interface GetUserDetailsRequestByForgerockSuccessAction extends Action {
  type: action_types.GET_USER_DETAILS_BY_FORGEROCK_REQUESTS_SUCCESS;
  userDetailsByForgerock: User;
  getStates: any;
}

interface ValdateUserPermissionsSuccessAction extends Action {
  type: action_types.VALIDATE_USER_PERMISSIONS_SUCCESS;
  userPermissionValidated: boolean;
}

interface GetEnvAction extends Action {
  type: action_types.GET_ENV_SUCCESS;
  e: boolean;
}

interface UpdateSelectedUserIdSuccess extends Action {
  type: action_types.UPDATE_SELECTED_USER_ID;
  selectedUserId: string
}

export type UserDetailsAction =
  | GetUserDetailsRequestByForgerockSuccessAction
  | ValdateUserPermissionsSuccessAction
  | GetEnvAction
  | UpdateSelectedUserIdSuccess
  | SessionAliveFailureAction
  | SessionAliveSuccessAction
  | AjaxCallActions;

type ThunkResult<R> = ThunkAction<R, StoreState, undefined, UserDetailsAction>;

export const checkSessionAliveFailureAction = (): SessionAliveFailureAction => ({ 
  isSessionAlive: false, 
  type: action_types.SESSION_ALIVE_FAILURE
});

export const setSessionAlive = ():SessionAliveSuccessAction => ({
  isSessionAlive: true,
  type: action_types.SESSION_ALIVE_SUCCESS
});

export const getUserDetailsRequestByForgerockSuccessAction = (
  userDetailsByForgerock: User, getStates: any
): GetUserDetailsRequestByForgerockSuccessAction => ({
  userDetailsByForgerock,
  getStates,
  type: action_types.GET_USER_DETAILS_BY_FORGEROCK_REQUESTS_SUCCESS
});

export const getUserDetailsRequestByForgerock = (forgeRockId: string) => {
  const headers = { Authorization: `Bearer ${auth.getAccessToken()}`, 'x-id-token': `${auth.getIdToken()}` };
  return async (dispatch: ThunkDispatch<StoreState, void, UserDetailsAction>, getState: any) => {
    dispatch(beginAjaxCall());
    const mockUsers = config.IS_MOCK.MOCK_USERS ? 1 : 0;

    try {
      const response = await axios
        .get(
          `${config.API_GATEWAY.URL}/user/getuser/${forgeRockId}/${mockUsers}`,
          {
            headers
          }
        );
      const userDetailsByForgerock = response.data;
      dispatch(
        getUserDetailsRequestByForgerockSuccessAction(userDetailsByForgerock, getState())
      );
    } catch (error) {
      dispatch(ajaxCallError(error));
      throw error;
    }
  };
};

export const validateUserPermissionsAction = (
  userPermissionValidated: boolean
): ValdateUserPermissionsSuccessAction => ({
  userPermissionValidated,
  type: action_types.VALIDATE_USER_PERMISSIONS_SUCCESS
});

export const validateUserPermissions = (
  family_name: string,
  loggedInUserPermissionIds: number[]
): ThunkResult<Promise<void>> => {
  const isUserExternal =
    family_name.toUpperCase() === userTypeEnums.INTERNAL_USER.toUpperCase()
      ? "0"
      : "1";
  const currentUserPermissions = loggedInUserPermissionIds.length
    ? loggedInUserPermissionIds.join("-")
    : "0-0";

  return dispatch => {
    dispatch(beginAjaxCall());

    return axios
      .get(
        `${config.API_GATEWAY.URL}/permissions/${isUserExternal}/${currentUserPermissions}`
      )
      .then(response => {
        dispatch(validateUserPermissionsAction(response.data.iupv));
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const GetEnvSuccessAction = (e: boolean): GetEnvAction => ({
  type: action_types.GET_ENV_SUCCESS,
  e
});

export const getEnv = (): ThunkResult<Promise<void>> => {
  const headers = { 'Authorization': `Bearer ${auth.getAccessToken()}`, 'x-id-token': `${auth.getIdToken()}`}
  return dispatch => {
    dispatch(beginAjaxCall());
    return axios
      .get(`${config.API_GATEWAY.URL}/nav`, {headers: headers})
      .then(response => {
        const envDetails = response.data.e;
        console.log("SHOW CREATE MENU?", envDetails);
        dispatch(GetEnvSuccessAction(envDetails));
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const updateSelectedUserId = (selectedUserId : string): 
  UpdateSelectedUserIdSuccess => ({
    selectedUserId,
    type: action_types.UPDATE_SELECTED_USER_ID
  });