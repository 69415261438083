import * as types from '../actions/action-types';
import { AvailabilityActions } from '../actions/availability.actions';
import { Availability } from '../models/assessment-associate';
import initialState from './initialState';

export function AvailabilityReducer(
  state: Availability[] = initialState.availabilities,
  action: AvailabilityActions
): Availability[] {
  if (action.type === types.LOAD_AVAILABILITIES_SUCCESS) {
          return action.availabilities;
  }else{
    return state;
  }
  
  // switch (action.type) {
  //   case types.LOAD_AVAILABILITIES_SUCCESS:
  //     return action.availabilities;
  //   default:
  //     return state;
  // }
}
