import * as React from 'react';
import { MediaRequestFile } from '../../../../models/media-request';
import { LearnerUnit } from '../../../../models/LearnerUnit';
import Dropzone from 'react-dropzone';
import uploadingImage from "../upload-24.svg";
require('./dragzone.scss');
import warningImage from "../warning-sm-18.svg";
import { useUserLoginState } from "../../../../shared/context-api/hooks";
import { HasWriteAccess } from "../../../../components/common/user-validation";

interface SampleMediaRequestLearnersListRowProps {
  sampleMediaRequestLearner: LearnerUnit;
  mediaRequestFiles: MediaRequestFile[];
  showIds?: boolean;
  allowUpload: boolean;
  uploadFileEvent: (file: File[], unitId: string, learnerId: string) => void;
  showFiles: (
    learnerUnit: LearnerUnit,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  isRejected: MediaRequestFile[];
  isConsortia: boolean;
}
const SampleMediaRequestLearnersListRow = ({
  sampleMediaRequestLearner,
  mediaRequestFiles,
  showIds = false,
  allowUpload = false,
  uploadFileEvent,
  showFiles,
  isRejected,
  isConsortia
}: SampleMediaRequestLearnersListRowProps) => {

  const onDrop = (files: File[]) => {
    return uploadFileEvent(
      files,
      sampleMediaRequestLearner.unitId,
      sampleMediaRequestLearner.learnerId
      // "" // sampleMediaRequestLearner.learnerId   To avoid the Duplicate file upload issue, files will be uploaded always at Unit level.
    );
  };
  const userLoginstate = useUserLoginState();

  return (
    <tr>
      <td>{sampleMediaRequestLearner.learningProviderCode} </td>
      <td>{sampleMediaRequestLearner.learningProviderName} </td>
      {showIds && <td>{sampleMediaRequestLearner.learnerCode} </td>}
      <td>
        {sampleMediaRequestLearner.firstName +
          ' ' +
          sampleMediaRequestLearner.surname}
      </td>
       <td>{sampleMediaRequestLearner.unitName}</td>
      <td>{isRejected.length > 0  && (
        // <PearsonElementsSDK.Icon name="warning-sm-18" />
        <img src={warningImage} className="warningImage"/>
      )}&nbsp;
        {mediaRequestFiles.length > 0 && (
          <a
            href="#"
            onClick={showFiles.bind(event, sampleMediaRequestLearner)}
          >
            {mediaRequestFiles.length} file(s) uploaded
          </a>
        )}
        {mediaRequestFiles.length === 0 && '0 file(s) uploaded' }
      </td>
      <td style={{display: allowUpload ? '' : 'none' }}>

        <Dropzone onDrop={onDrop}>
          {({ getRootProps, getInputProps, isDragActive }) => {

            return (
              <div
                {...getRootProps()}
                className={'dragzone ' + (isDragActive ? ' isDragActive' : '') + (HasWriteAccess(userLoginstate) ? '' : 'dragzone-disabled')}

              >
                <input {...getInputProps()} disabled={!HasWriteAccess(userLoginstate)}/>
                { isDragActive ? (
                  // <p>Drop file here...</p>
                  <p><span className="tooltipdrop">Dragg &amp; drop a file here, or click to select a file to upload.</span>
                  <img src={uploadingImage} width="30px" className="color" />                
                  </p>
                ) : (
                  // <p>Drag &amp; drop a file here, or click to select a file to upload.</p>
                  <p className="tooltipInit">
                    <span className="tooltipdropInit">Drag &amp; drop a file here, or click to select a file to upload.</span>
                    <img src={uploadingImage} width="30" height="30" /></p> 
                ) 
              }
              </div>
            );
          }}
        </Dropzone>
      </td>
    </tr>
  );
};

export default SampleMediaRequestLearnersListRow;
