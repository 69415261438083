import axios from 'axios';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { AssessmentAssociate } from '../models/assessment-associate';
import * as action_types from './action-types';
import {
  ajaxCallError,
  beginAjaxCall,
  AjaxCallActions
} from './ajax-status.actions';

import config from '../app-config';
import { StoreState } from '../store/store-state';

import Auth from './auth/auth';

const auth = new Auth();

interface GetAssessmentAssociateSuccessAction extends Action {
  type: action_types.GET_ASESSMENT_ASSOCIATE_SUCCESS;
  assessmentAssociate: AssessmentAssociate;
}

export type AssessmentAssociateAction =
  | GetAssessmentAssociateSuccessAction
  | AjaxCallActions;

export const getAssessmentAssociateSuccessAction = (
  assessmentAssociate: AssessmentAssociate
): GetAssessmentAssociateSuccessAction => ({
  assessmentAssociate,
  type: action_types.GET_ASESSMENT_ASSOCIATE_SUCCESS
});

export const getAssessmentAssociate = (aaNumber: string) => {
  const headers = { 'Authorization': `Bearer ${auth.getAccessToken()}`, 'x-id-token': `${auth.getIdToken()}`}
  return (
    dispatch: ThunkDispatch<StoreState, void, AssessmentAssociateAction>
  ) => {
    dispatch(beginAjaxCall());

    return axios
      .get(`${config.API_GATEWAY.URL}/assessment-associates`, {
        headers,
        params: {
          aaNumber
        }
      })
      .then(response => {
        const assessmentAssociates = response.data;
        dispatch(
          getAssessmentAssociateSuccessAction(
            assessmentAssociates.map(
              (assessmentAssociate: AssessmentAssociate) => {
                const a = new AssessmentAssociate();
                a.id = assessmentAssociate.id;
                a.title = assessmentAssociate.title;
                a.aaNumber = assessmentAssociate.aaNumber;
                a.firstName = assessmentAssociate.firstName;
                a.surname = assessmentAssociate.surname;
                a.addresses = assessmentAssociate.addresses;
                a.mobile = assessmentAssociate.mobile;
                a.emailAddress = assessmentAssociate.emailAddress;
                a.telephone = assessmentAssociate.telephone;

                return a;
              }
            )[0]
          )
        );
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};
