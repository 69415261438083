export class MediaUploadFile {
    file: File;
    learningProviderId: string;
    mediaSetId: string;
    unitId: string;
    learnerId: string;
}

export class MediaUploadStatus {
    fileKey: string;
    fileName: string;
    percentage: number;  
    completed: boolean;
    aborted: boolean;
    inProgress: boolean;
    error: Error | undefined;  
    cancelUpload: any;
    startUpload: any;
}

export class MediaUploadCredentials {
    cloudfrontUrl: string;
    bucket: string;
    region: string;
    accessKeyId: string;
    secretAccessKey: string;
    sessionToken: string;
    environment: string;
    capabilityCode: string;
}
