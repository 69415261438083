import { connect } from "react-redux";
import { MediaUpload, StateProps } from "./media-upload";
import { StoreState } from "../../store/store-state";
import { MediaUploadStatus } from "../../models/media-upload";
import _ from "lodash";

const mapStateToProps = (state: StoreState): StateProps => {  
  const orderedStatus = _.orderBy(state.mediaUploadStatus, (m: MediaUploadStatus) => [m.fileName, m.fileKey], ["desc","desc"]);
  return {mediaUploadStatus: orderedStatus}
};

export default connect<StateProps>(
  mapStateToProps
)(MediaUpload);

