import * as React from 'react';

import {Button} from 'ukas.uux.react-components';
interface ConfirmationModalProps {
    closeBtnHandler : ( event: React.UIEvent<HTMLSpanElement>) => void;
    infoMsgRender:() => JSX.Element;
    closeBtnText?: string;
}

const InformationModalGeneric = ({ closeBtnHandler, infoMsgRender, closeBtnText }: ConfirmationModalProps) =>{
  return ( 
    <div>
      <div >
        {infoMsgRender()}
      </div>
      
      <div className="pull-right"><br /><br />
        <Button
            onClick={closeBtnHandler.bind(event)}>
            {closeBtnText ? closeBtnText : "Close"}
        </Button>
        <br /><br />         
      </div>
    </div>
   
  );
};

export default InformationModalGeneric;