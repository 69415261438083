import * as React from 'react';
import moment from 'moment';

import { LearningProvider } from 'models/learning-provider';
import { MediaRequest } from 'models/media-request';
import { LearnerUnit } from "models/LearnerUnit";
import { LearnerUnitInput } from './create-media-set';

import { Button, TextInput, Table, Select, DatePicker } from 'ukas.uux.react-components';

export interface CreateMediaSetFormProps {
  mediaSet: MediaRequest;
  learnerUnit: LearnerUnitInput;
  learningProvider: LearningProvider;
  onFieldChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onDeadlineChanged: (newValue: any) => void;
  onSave: (event: any) => void;
  onClear: () => void;
  onLearnerDateOfBirthChange: (newValue: any) => void;
  onLearnerUnitInputChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onLearnerAdd: (event: React.FormEvent<HTMLSelectElement>) => void;
  errors: any;
  saveButtonEnabled: boolean;
  addLearnerEnabled: boolean;
  mediaSampleTypes: string[];
  onMediaSampleTypeChange: (event: React.FormEvent<HTMLSelectElement>) => void;
  selectedMediaSampleType: string;
  genderTypes: string[];
  onGenderChange: (event: React.FormEvent<HTMLSelectElement>) => void;
}

const CreateMediaSetForm = ({
  mediaSet,
  learnerUnit,
  learningProvider,
  onFieldChange,
  onSave,
  onClear,
  onDeadlineChanged,
  onLearnerDateOfBirthChange,
  onLearnerUnitInputChange,
  onLearnerAdd,
  errors,
  saveButtonEnabled,
  addLearnerEnabled,
  mediaSampleTypes,
  onMediaSampleTypeChange,
  selectedMediaSampleType,
  genderTypes,
  onGenderChange
}: CreateMediaSetFormProps) => {
  const onSubmit = (event: any) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    form.reset();
    onClear();
  };

  return (
    <div>
      <div className="pe-template__single">
        <h1 className="pe-page-title">Create Media Set</h1>
      </div>
      <div className="pe-template__single content">
        <form className="pe-form" onSubmit={onSubmit}>
          <div className="pe-form--group">
            <TextInput id="qualificationId"
              label='Qualification id'
              value={mediaSet.qualificationId}
              feedbackType={errors.qualificationId !== undefined ? 'invalid' : 'valid'}
              feedback={errors.qualificationId}
              onChange={onFieldChange} maxLength={10} />

            <TextInput id="qualificationName"
              label='Qualification name'
              value={mediaSet.qualificationName}
              feedbackType={errors.qualificationName !== undefined ? 'invalid' : 'valid'}
              feedback={errors.qualificationName}
              onChange={onFieldChange} maxLength={10} />
          </div>
          <div className="pe-form--group">
            <TextInput
              id="learningProviderId"
              label={'Learning provider id (read only)'}
              disabled={true}
              readOnly={true}
              value={learningProvider.id}
              feedbackType={
                errors.learningProviderId !== undefined ? 'invalid' : 'valid'
              }
              feedback={errors.learningProviderId} maxLength={10}
            />
          </div>
          <div className="pe-form--group">
            <TextInput
              id="learningProviderName"
              label={'Learning provider name (read only)'}
              readOnly={true}
              disabled={true}
              value={learningProvider.name}
              feedbackType={
                errors.learningProviderName !== undefined ? 'invalid' : 'valid'
              }
              feedback={errors.learningProviderName} maxLength={10}
            />
          </div>
          <div className="pe-form--group">
            <TextInput
              id="standardsVerifierId"
              label={'Standards verifier id'}
              onChange={onFieldChange}
              value={mediaSet.standardsVerifierId}
              feedbackType={
                errors.standardsVerifierId !== undefined ? 'invalid' : 'valid'
              }
              feedback={errors.standardsVerifierId} maxLength={10}
            />
          </div>
          <div className="pe-form--group">
            <TextInput
              id="standardsVerifierName"
              label={'Standards verifier name'}
              onChange={onFieldChange}
              value={mediaSet.standardsVerifierName}
              feedbackType={
                errors.standardsVerifierName !== undefined ? 'invalid' : 'valid'
              }
              feedback={errors.standardsVerifierName} maxLength={10}
            />
          </div>
          <div className="pe-form--group">
            <DatePicker
              id="deadlineDateTime"
              // dateFormat="dd/mm/yyyy"
              // value={mediaSet.deadlineDateTime}
              value={mediaSet.deadlineDateTime? moment(mediaSet.deadlineDateTime).format("YYYY-MM-DD") : ""}
              label="Select deadline date (dd/mm/yyyy)"
              // datePickerValue={moment(mediaSet.deadlineDateTime).format('DD/MM/YYYY')}
              feedbackType={ errors.deadlineDateTime !== undefined ? 'invalid' : 'valid'}
              feedback={errors.deadlineDateTime}
              onChange={onDeadlineChanged}
            />
          </div>
          <div className="pe-form--group">
            <TextInput
              id="purpose"
              label={'Purpose'}
              onChange={onFieldChange}
              value={mediaSet.purpose}
              feedbackType={errors.purpose !== undefined ? 'invalid' : 'valid'}
              feedback={errors.purpose} maxLength={10}
            />
          </div>
          <div className="pe-form--group">
            <Select
              id="mediaSampleType"
              label="Select type..."
              onChange={onMediaSampleTypeChange}
              value={mediaSampleTypes}
            ><option value={selectedMediaSampleType}>
              </option></Select>
          </div>
          <Table>
            <caption>Learners and Units{mediaSet.learnerUnits.length === 0 && (<span><br />(Add learners below)</span>)}</caption>
            <thead>
              <tr>
                <th>Learner Id</th>
                <th>Learner Name</th>
                <th>Date of Birth</th>
                <th>Gender</th>
                <th>Unit Id</th>
                <th>Unit</th>
              </tr>
            </thead>
            <tbody>
              {mediaSet.learnerUnits.map((learner: LearnerUnit) => (
                <tr key={learner.learnerId + '-' + learner.unitId}>
                  <td>{learner.learnerId}</td>
                  <td>{learner.firstName + ' ' + learner.surname}</td>
                  <td>{learner.dateOfBirth.toDateString()}</td>
                  <td>{learner.gender}</td>
                  <td>{learner.unitId}</td>
                  <td>{learner.unitName}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <fieldset>
            <legend>Add Learner & Unit</legend>
            <TextInput
              id="learnerId"
              label="Learner Id"
              onChange={onLearnerUnitInputChange}
              value={learnerUnit.learnerId} maxLength={10}
              feedbackType={errors.learnerId !== undefined ? 'invalid' : 'valid'}
              feedback={errors.learnerId}
            />
            <TextInput
              id="firstName"
              label="Learner FirstName"
              onChange={onLearnerUnitInputChange}
              value={learnerUnit.firstName} maxLength={10}
              feedbackType={errors.firstName !== undefined ? 'invalid' : 'valid'}
              feedback={errors.firstName}
            />
            <TextInput
              id="surname"
              label="Learner Surname"
              onChange={onLearnerUnitInputChange}
              value={learnerUnit.surname} maxLength={10}
              feedbackType={errors.surname !== undefined ? 'invalid' : 'valid'}
              feedback={errors.surname}
            />
            <DatePicker
              id="dateOfBirth"
              value={learnerUnit.dateOfBirth ? moment(learnerUnit.dateOfBirth).format("YYYY-MM-DD") : ""}
              label="Select date of birth (dd/mm/yyyy)"
              feedbackType={
                errors.dateOfBirth !== undefined ? 'invalid' : 'valid'
              }
              feedback={errors.dateOfBirth}
              onChange={onLearnerDateOfBirthChange}
            />
            <Select
              id="gender"
              label="Select gender..."
              onChange={onGenderChange}
              value={genderTypes}
              feedbackType={errors.gender !== undefined ? 'invalid' : 'valid'}
              feedback={errors.gender}
            >
              <option value={learnerUnit.gender}></option>
            </Select>
            <TextInput
              id="unitId"
              label="Unit Id"
              onChange={onLearnerUnitInputChange}
              value={learnerUnit.unitId} maxLength={10}
              feedbackType={errors.unitId !== undefined ? 'invalid' : 'valid'}
              feedback={errors.unitId}
            />
            <TextInput
              id="unitName"
              label="Unit Name"
              onChange={onLearnerUnitInputChange}
              value={learnerUnit.unitName} maxLength={10}
              feedbackType={errors.unitName !== undefined ? 'invalid' : 'valid'}
              feedback={errors.unitName}
            />
            <br />
            <Button
              id="addLearnerButton"
              onClick={()=>onLearnerAdd}
              variant="primary"
              disabled={addLearnerEnabled === false ? true : false}
            >
              Add
            </Button>
          </fieldset>
          <div className="pe-strategy__centered--small-gap">
            <div>
              <Button
                id="saveButton"
                variant="secondary"
                onClick={(e) => onSave(e)}
                disabled={saveButtonEnabled === false ? true : false}
              >
                Submit
              </Button>
              &nbsp;
              <Button id="clearButton" onClick={(e) => onClear()}>
                Clear
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateMediaSetForm;
