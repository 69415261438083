import React from "react";
import { useEffect } from "react";
import { useReducer } from "react";
import { userDataReducer } from "./reducers";
import { userDataState, userDataDispatch } from "./context";
import { Types as UserActionTypes } from "./context-api-action-types";

import {User} from "../../models/user-details-forgerock-request";

import { getUser } from "../../helpers/user-helper";

export type initialStateType = {
  cognitoPearsonUID: string|undefined,
  userData: User
};

const initialSate: initialStateType = {
  cognitoPearsonUID: "",
  userData: new User(),
};

export const UserDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(userDataReducer, initialSate);

  useEffect(() => {
    (async () => {
      const cognitoData = JSON.parse(localStorage.getItem("payload")!);
      if (cognitoData) {
        const pearsonUID = cognitoData["profile"];
        const userName = cognitoData["given_name"];

        let userData = JSON.parse(localStorage.getItem("userData-" + pearsonUID)!);
        if (!userData ) {

                getUser(pearsonUID).then((user: User | undefined) => {
              // check it is the 
            
              if (!user) {
                localStorage.setItem("userData-" + pearsonUID, JSON.stringify(user));          
              }
              userData = JSON.parse(localStorage.getItem("userData-" + pearsonUID)!) || user;
           
              dispatch({
                type: UserActionTypes.UserLoggedInAction,
                payload: { userData, userName },
              });
            });
        }
        else {
          dispatch({
            type: UserActionTypes.UserLoggedInAction,
            payload: { userData, userName },
          });
        }
      }
    })();
  }, [state.cognitoPearsonUID]);

  return (
    <userDataState.Provider value={state}>
      <userDataDispatch.Provider value={dispatch}>
        {children}
      </userDataDispatch.Provider>
    </userDataState.Provider>
  );
};
export default UserDataProvider;
