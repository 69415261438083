import * as Evaporate from 'evaporate';
import * as crypto from 'crypto';
const aws = require('aws-sdk'); 

import { MediaUploadCredentials, MediaUploadFile } from '../models/media-upload';

export class S3UploadMultipart {

    private mediaUploadFile: MediaUploadFile;
    private region: string;
    private bucket: string;
    // private cloudfrontUrl: string;
    private file: File;
    private filekey: string;
    private evaporate: Evaporate;
    private amzHeadersCommon: { [key: string]: string };
    private amzHeadersAtInitiate: { [key: string]: string };
    private notSignedHeadersAtInitiate: { [key: string]: string };
    private awskey: string;
    private awssecret: string;
    private awstoken: string;
    private environment: string;
    private capabilityCode: string;

    constructor(mediaUploadCredentials: MediaUploadCredentials, mediaUploadFile: MediaUploadFile, fileKey: string) {
        this.bucket = mediaUploadCredentials.bucket;
        // this.cloudfrontUrl = mediaUploadCredentials.cloudfrontUrl;
        this.region = mediaUploadCredentials.region;
        this.awskey = mediaUploadCredentials.accessKeyId;
        this.awssecret = mediaUploadCredentials.secretAccessKey;
        this.awstoken = mediaUploadCredentials.sessionToken;
        this.mediaUploadFile = mediaUploadFile;
        this.filekey = fileKey;
        this.environment = mediaUploadCredentials.environment;
        this.capabilityCode = mediaUploadCredentials.capabilityCode;
    }
    
    onFinishS3Put = (signResult: string) => {
        return console.log('File upload complete: ', signResult);
    };

    onProgress = (percent: number, status: string) => {
        return console.log('Progress: ', percent, status);
    };

    onStart = () => {
        return console.log('Starting');
    }

    onError = (error: string) => {
        return console.log('Error: ', error);
    };

    addFileToUpload = () =>  {
        const self = this;

        this.amzHeadersCommon = {};
        this.amzHeadersCommon['x-amz-security-token'] = self.awstoken;

        this.amzHeadersAtInitiate = {};
        this.amzHeadersAtInitiate['x-amz-security-token'] = self.awstoken;

        this.notSignedHeadersAtInitiate = {};
        this.notSignedHeadersAtInitiate['Content-Disposition'] = `attachment; filename="${this.mediaUploadFile.file.name.replace(/[^\w\d_\-\.]+/ig, '')}"`;

        
        // const metadataString = 'LearningProviderId=' + this.mediaUploadFile.learningProviderId 
        //     + '&MediaSetId=' + this.mediaUploadFile.mediaSetId
        //     + '&UnitId=' + this.mediaUploadFile.unitId 
        //     + '&LearnerId=' + this.mediaUploadFile.learnerId
        //     + '&SourceSystem=DLWT';
        const metadataString = `LearningProviderId=${this.mediaUploadFile.learningProviderId}&MediaSetId=${this.mediaUploadFile.mediaSetId}&UnitId=${this.mediaUploadFile.unitId}${this.mediaUploadFile.learnerId && '&LearnerId='+this.mediaUploadFile.learnerId}&SourceSystem=learner-media&CapabilityCode=${this.capabilityCode}&Environment=${this.environment}`;
        console.log("metadataString : ", metadataString)
        this.amzHeadersAtInitiate['x-amz-tagging'] = metadataString;

        self.file = this.mediaUploadFile.file;

        self.onStart();
    };

    startUpload = () => {
        const self = this;
        self.uploadToS3(self.file);
    }

    abortUpload = () => {
        const self = this;

        return self.evaporate && self.evaporate.cancel();
    };

    private authBysecret = (signParams: any, signHeaders: any, stringToSign: string, dateString: any, canonicalRequest: any): Promise<string> => {
        const self = this;

        const stringToSignDecoded = decodeURIComponent(stringToSign)
        const requestScope = stringToSignDecoded.split('\n')[2];
        const [date, region, service, signatureType] = requestScope.split('/');

        return new Promise((resolve, reject) => { 
            const kDate = crypto.createHmac('sha256', `AWS4${self.awssecret}`).update(date).digest();
            const kRegion = crypto.createHmac('sha256', kDate).update(region).digest();
            const kService = crypto.createHmac('sha256', kRegion).update(service).digest();
            const kType = crypto.createHmac('sha256', kService).update(signatureType).digest();
            const kSigning = crypto.createHmac('sha256', kType).update(stringToSignDecoded).digest('hex');

            resolve(kSigning);
        });
    };
    
    private uploadToS3 = (file: File) => {
        const self = this;

        const evaporateOptions: Evaporate.CreateConfig = {
            customAuthMethod: self.authBysecret,
            // aws_url:self.cloudfrontUrl,
            aws_key: self.awskey,            
            bucket: self.bucket,
            cloudfront: true,
            computeContentMd5: true,
            awsSignatureVersion: '4',
            awsRegion: self.region,            
            cryptoMd5Method: (data: ArrayBuffer) => {
                return aws.util.crypto.md5(data, 'base64');
            },
            cryptoHexEncodedHash256: (data: ArrayBuffer) => {
                return aws.util.crypto.sha256(data, 'hex');
            }
        };
        return Evaporate.create(evaporateOptions).then((evaporate: Evaporate) => {
            const addConfig: Evaporate.AddConfig = {
                name: self.filekey,
                file,
                notSignedHeadersAtInitiate: self.notSignedHeadersAtInitiate,
                xAmzHeadersCommon: self.amzHeadersCommon,
                xAmzHeadersAtInitiate: self.amzHeadersAtInitiate,
                progress: (progressValue: number, stats: Evaporate.TransferStats) => {
                    return self.onProgress(progressValue, progressValue === 100 ? 'Finalizing' : 'Uploading');
                },
                complete: (xhr: XMLHttpRequest, awsKey: string, stats: Evaporate.TransferStats) => {
                    if (xhr.status === 200) {
                        self.onProgress(100, 'Upload completed');
                    } else {
                        return self.onError('Upload error: ' + xhr.status);
                    }
                },
                error: (msg: string) => {
                    return self.onError(msg);
                }
            };
            self.evaporate = evaporate;
            evaporate.add(addConfig).then(
                (awsKey: string) => {
                    return self.onFinishS3Put(awsKey);
                },
                (errorReason: string) => {
                    return self.onError(errorReason);
                }
            );
        });
    };
}

export default S3UploadMultipart;
