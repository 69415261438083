import * as React from 'react';
import axios from 'axios';
import config from '../../app-config';

import AuthForgeRock from '../../actions/auth/auth';

const auth = new AuthForgeRock();
const pageTitle ="Learner Work Transfer";
interface HomeState {
  url: string;
}

class Home extends React.Component<{}, HomeState> {
  constructor(props: {}) {
    super(props);
    document.title=pageTitle;
    this.state = {
      url: 'not url'
    };

    this.Download = this.Download.bind(this);
  }

  render() {
    return (
      <div className='content'>
        <div className="pe-template__single">
          <h1 className="pe-page-title">{pageTitle}</h1>
        </div>
        <div className="pe-template__single">
        <p>
        This portal is used to upload and send learner work for assessment.
        </p>
        </div>
      </div>
    );
  }

  Download(event: React.FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const headers = { 'Authorization': `Bearer ${auth.getAccessToken()}`, 'x-id-token': `${auth.getIdToken()}`}
    return axios
      .get(`${config.API_GATEWAY.URL}/assessment-associates/url`, {headers: headers})
      .then(response => {
        const downloadLink = form.childNodes[1] as HTMLAnchorElement;
        downloadLink.href = response.data.url;
        downloadLink.download = 'true';
        downloadLink.click();
        // window.open(response.data.url);
        // form.action = response.data.url;
        // form.submit();
        return this.setState({ url: response.data.url });
      });
  }

      getUrl(): string {
    return this.state.url;
  }

  componentDidMount(){ 
    if (!auth.isAuthenticated()) {
      auth.signin();
    }
  }
}

export default Home;
