import axios from "axios";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import * as action_types from "./action-types";
import {
  ajaxCallError,
  beginAjaxCall,
  AjaxCallActions
} from "./ajax-status.actions";
import { globalError, GlobalErrorActions } from "./global-error.actions";

import { MediaRequest, CreateMediaRequestFile, MediaRequestFile } from "../models/media-request";
import { LearnerUnit } from "../models/LearnerUnit";
import config from "../app-config";
import { StoreState } from "../store/store-state";
import AuthForgeRock from '../actions/auth/auth';
const auth = new AuthForgeRock();

interface CreateMediaSetSuccessAction extends Action {
  type: action_types.CREATE_MEDIA_SET_SUCCESS;
  mediaRequest: MediaRequest;
}

interface CreateMediaSetFileSuccessAction extends Action {
  type: action_types.CREATE_MEDIA_FILE_SET_SUCCESS;
  mediaRequest: MediaRequest;
}

interface SubmitMediaSetSuccessAction extends Action {
  type: action_types.SUBMIT_MEDIA_SET_SUCCESS;
  mediaRequest: MediaRequest;
}

interface MediaSetUpdatedSuccessAction extends Action {
  type: action_types.MEDIASET_UPDATED_SUCCESS;
  mediaRequest: MediaRequest;
}

export type MediaSetAction =
  | CreateMediaSetSuccessAction
  | SubmitMediaSetSuccessAction
  | CreateMediaSetFileSuccessAction
  | MediaSetUpdatedSuccessAction
  | AjaxCallActions
  | GlobalErrorActions;


export const createMediaSetSuccessAction = (
  mediaSet: MediaRequest
): CreateMediaSetSuccessAction => ({
  type: action_types.CREATE_MEDIA_SET_SUCCESS,
  mediaRequest: mediaSet
});

export const createMediaSetFileSuccessAction = (
  mediaRequest: MediaRequest
): CreateMediaSetFileSuccessAction => ({
  type: action_types.CREATE_MEDIA_FILE_SET_SUCCESS,
  mediaRequest
});

export const submitMediaSetSuccessAction = (
  mediaSet: MediaRequest
): SubmitMediaSetSuccessAction => ({
  type: action_types.SUBMIT_MEDIA_SET_SUCCESS,
  mediaRequest: mediaSet
});

export const mediaSetUpdatedSuccessAction = (mediaSet: MediaRequest) 
  : MediaSetUpdatedSuccessAction => ( {type: action_types.MEDIASET_UPDATED_SUCCESS, mediaRequest: mediaSet});

export const createMediaSet = (mediaSet: MediaRequest) => {
  return (dispatch: ThunkDispatch<StoreState, void, MediaSetAction>) => {
    dispatch(beginAjaxCall());
    
    return axios
      .post(
        `${config.API_GATEWAY.URL}/media-sets/${mediaSet.learningProviderId}`,
        JSON.stringify(mediaSet),
        { headers: { 
          "content-type": "application/json",
          "Authorization": `Bearer ${auth.getAccessToken()}`,
          'x-id-token': `${auth.getIdToken()}`
        } }
      )
      .then(postResponse => {                
        const mediaSetId = postResponse.data.mediaSetId;

        // new mediaset object
        const newMediaSet = Object.assign({}, mediaSet, { mediaSetId,  status: postResponse.data.status });
        dispatch(createMediaSetSuccessAction(newMediaSet));
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        dispatch(globalError(error));
        throw error;
      });
  };
};

export const addMediaSetFile = (file: CreateMediaRequestFile) => {
  return (dispatch: ThunkDispatch<StoreState, void, MediaSetAction>) => {
    dispatch(beginAjaxCall());

    return axios
      .post(
        `${config.API_GATEWAY.URL}/media-sets/file/${file.learningProviderId}`,
        JSON.stringify(file),
        { headers: { 
          "content-type": "application/json",
          "Authorization": `Bearer ${auth.getAccessToken()}`,
          'x-id-token': `${auth.getIdToken()}`
        } }
      )
      .then(postResponse => {                
        const mediaRequest =  Object.assign(new MediaRequest(), postResponse.data.mediaSet);
        mediaRequest.proxyName = mediaRequest.learningProviderCode+ " "+ mediaRequest.learningProviderName;
        dispatch(createMediaSetFileSuccessAction(mediaRequest));
      })
      .catch(addMediaSetFilError => {
        dispatch(ajaxCallError(addMediaSetFilError));
        dispatch(globalError(addMediaSetFilError));
        throw addMediaSetFilError;
      });
  };
};

export const submitMediaSet = (mediaSet: MediaRequest, mediaSetSubmittedBy: string) => {
  const submitMediaSetObj = Object.assign({
    learningProviderId:mediaSet.learningProviderId,
    mediaSetId:mediaSet.mediaSetId,
    mediaSetSubmittedBy: (mediaSetSubmittedBy)
  });
  return (dispatch: ThunkDispatch<StoreState, void, MediaSetAction>) => {
    dispatch(beginAjaxCall());
    
    return axios
      .post(
        `${config.API_GATEWAY.URL}/media-sets/${mediaSet.learningProviderId}/submit`,
        JSON.stringify(submitMediaSetObj),
        { headers: { 
          "content-type": "application/json",
          "Authorization": `Bearer ${auth.getAccessToken()}`,
          'x-id-token': `${auth.getIdToken()}`
        } }
      )
      .then(postResponse => {      
        const mediaRequest =  Object.assign(new MediaRequest(), postResponse.data.mediaSet);  
        mediaRequest.proxyName = mediaRequest.learningProviderCode+ " "+ mediaRequest.learningProviderName;     
        dispatch(submitMediaSetSuccessAction(mediaRequest));
      })
      .catch(submitMediaSetError => {
        dispatch(ajaxCallError(submitMediaSetError));
        dispatch(globalError(submitMediaSetError));
        throw submitMediaSetError;
      });
  };
};

export const removeFileFromMediaSet = (mediaRequest:MediaRequest, file:MediaRequestFile, learnerUnit:LearnerUnit) => {
  return (dispatch: ThunkDispatch<StoreState, void, MediaSetAction>) => {
    dispatch(beginAjaxCall());

    // Todo: call node to remove file.
    const url = `${config.API_GATEWAY.URL}/media-sets/${mediaRequest.learningProviderId}/files/removeFile`;
    const body = JSON.stringify({      
        learningProviderId: mediaRequest.learningProviderId,
        mediaSetId: mediaRequest.mediaSetId,
        fileId: file.fileId,
        learnerId: learnerUnit.learnerId,  
        unitId: learnerUnit.unitId                          
    });

    return axios
      .post(url, body, { 
        headers: { 
        "content-type": "application/json",
        "Authorization": `Bearer ${auth.getAccessToken()}`,
        'x-id-token': `${auth.getIdToken()}`
      } })
      .then(response => {
        const mediaSet = response.data.mediaSet as MediaRequest;
        mediaSet.proxyName = mediaSet.learningProviderCode+ " "+ mediaSet.learningProviderName;
        dispatch(mediaSetUpdatedSuccessAction(mediaSet));
      })
      .catch(removeFileFromMediaSetError => {
        dispatch(ajaxCallError(removeFileFromMediaSetError));
        dispatch(globalError(removeFileFromMediaSetError));
        throw removeFileFromMediaSetError;
      });
  };
};
