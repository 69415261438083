import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { MediaRequest, MediaRequestFile } from '../../../models/media-request';

import { LearnerUnit } from '../../../models/LearnerUnit';
import * as action_types from '../../../actions/action-types';
import {
  ajaxCallError,
  beginAjaxCall,
  AjaxCallActions
} from '../../../actions/ajax-status.actions';

import config from '../../../app-config';
import { StoreState } from '../../../store/store-state';

import AuthForgeRock from '../../../actions/auth/auth';
const auth = new AuthForgeRock();

interface GetSampleMediaRequestsCompletedAction extends Action {
  type: action_types.GET_SAMPLE_MEDIA_REQUESTS_COMPLETED;
  isGetSampleMediaRequestCompleted: boolean;
}

interface GetSampleMediaRequestsStartedAction extends Action {
  type: action_types.GET_SAMPLE_MEDIA_REQUESTS_STARTED;
  isGetSampleMediaRequestStarted: boolean;
}

interface GetSampleMediaRequestsSuccessAction extends Action {
  type: action_types.GET_SAMPLE_MEDIA_REQUESTS_SUCCESS;
  sampleMediaRequests: MediaRequest[];
}

interface AddLearnerErrorAction extends Action {
  type: action_types.ADD_LEARNER_ERROR;
  showAddLearnerError: boolean;
}


interface UpdateSampleMediaRequestSuccessAction extends Action {
  type: action_types.MEDIASET_UPDATED_SUCCESS;
  mediaRequest: MediaRequest;
}

interface CloseShowAddLearnerErrorModalAction extends Action {
  type: action_types.CLOSE_SHOW_LEARNER_ERROR_MODAL;
  showAddLearnerError: boolean;
}


interface RemoveFileSuccessAction extends Action {
  type: action_types.FILE_REMOVE_SUCCESS;
  mediaRequest: MediaRequest;
}

export type SampleMediaRequestsAction =
  | GetSampleMediaRequestsSuccessAction
  | GetSampleMediaRequestsCompletedAction
  | GetSampleMediaRequestsStartedAction
  | UpdateSampleMediaRequestSuccessAction
  | RemoveFileSuccessAction
  | AjaxCallActions
  | AddLearnerErrorAction
  | CloseShowAddLearnerErrorModalAction


type ThunkResult<R> = ThunkAction<
  R,
  StoreState,
  undefined,
  SampleMediaRequestsAction
>;

export const getSampleMediaRquestsSuccessAction = (
  sampleMediaRequests: MediaRequest[]
): GetSampleMediaRequestsSuccessAction => ({
  sampleMediaRequests,
  type: action_types.GET_SAMPLE_MEDIA_REQUESTS_SUCCESS
});


export const closeShowAddLearnerErrorModal =():CloseShowAddLearnerErrorModalAction =>({
  type: action_types.CLOSE_SHOW_LEARNER_ERROR_MODAL,
  showAddLearnerError: false
})


export const addLearnerError = (): AddLearnerErrorAction =>({
  type: action_types.ADD_LEARNER_ERROR,
  showAddLearnerError: true
})


export const updateSampleMediaRquestSuccessAction = (
  mediaRequest: MediaRequest
): UpdateSampleMediaRequestSuccessAction => ({
  mediaRequest,
  type: action_types.MEDIASET_UPDATED_SUCCESS
});

export const RemoveFileSuccessAction = (
  mediaRequest: MediaRequest
): UpdateSampleMediaRequestSuccessAction => ({
  mediaRequest,
  type: action_types.MEDIASET_UPDATED_SUCCESS
});

export const getSampleMediaRequests = (
  learningProviderId: string
): ThunkResult<Promise<void>> => {
  const headers = { 'Authorization': `Bearer ${auth.getAccessToken()}`, 'x-id-token': `${auth.getIdToken()}`}
  return dispatch => {
    dispatch(beginAjaxCall());
    dispatch(getSampleMediaRequestCompleted(false));
    dispatch(getSampleMediaRequestStarted(true));

    return axios
      .get(`${config.API_GATEWAY.URL}/sample-media-requests`, {        
        params: {
          learningProviderId
        },
        headers
      })
      .then(response => {
        const sampleMediaRequests = response.data;
        localStorage.setItem('mediaSetsCount',sampleMediaRequests.length)
        dispatch(
          getSampleMediaRquestsSuccessAction(
            sampleMediaRequests.map((sampleMediaRequest: MediaRequest) => {
              const mr = new MediaRequest();
              mr.consortiaId = sampleMediaRequest.consortiaId;
              mr.parentRequests = sampleMediaRequest.parentRequests;
              mr.parentLearningProviderCode=sampleMediaRequest.parentLearningProviderCode;
              mr.academicYear =sampleMediaRequest.academicYear;
              mr.mediaSetId = sampleMediaRequest.mediaSetId;
              mr.qualificationId = sampleMediaRequest.qualificationId;
              mr.qualificationName = sampleMediaRequest.qualificationName;
              mr.learningProviderId = sampleMediaRequest.learningProviderId;
              mr.learningProviderName = sampleMediaRequest.learningProviderName;
              mr.learningProviderCode = sampleMediaRequest.learningProviderCode;
              if(sampleMediaRequest.deadlineDateTime !== null){
                mr.deadlineDateTime = new Date(sampleMediaRequest.deadlineDateTime);
              }
              mr.numberOfLearners = sampleMediaRequest.numberOfLearners;
              mr.numberOfFiles = sampleMediaRequest.numberOfFiles;
              mr.submittedBy = sampleMediaRequest.submittedBy;
              mr.qualificationGroupCode= sampleMediaRequest.qualificationGroupCode;
              mr.standardsVerifierId = sampleMediaRequest.standardsVerifierId;
              mr.standardsVerifierName = sampleMediaRequest.standardsVerifierName;
              mr.learnerUnits = sampleMediaRequest.learnerUnits;
              mr.files = sampleMediaRequest.files;
              mr.status = sampleMediaRequest.status;
              mr.submissionDate = new Date(sampleMediaRequest.submissionDate);
              mr.version = sampleMediaRequest.version;
              mr.proxyName = sampleMediaRequest.learningProviderCode+ " "+ sampleMediaRequest.learningProviderName;
              
              return mr;
            })
          ) 
        );
        dispatch(getSampleMediaRequestCompleted(true));
      })
      .catch(error => {
        dispatch(getSampleMediaRequestCompleted(true));
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const getSampleMediaRequestCompletedAction = (
  isGetSampleMediaRequestCompleted : boolean
): GetSampleMediaRequestsCompletedAction => ({
  isGetSampleMediaRequestCompleted,
  type: action_types.GET_SAMPLE_MEDIA_REQUESTS_COMPLETED  
});

export const getSampleMediaRequestCompleted = (
  isGetSampleMediaRequestCompleted : boolean
) : GetSampleMediaRequestsCompletedAction => {
  return getSampleMediaRequestCompletedAction(isGetSampleMediaRequestCompleted)
};

export const getSampleMediaRequestStartedAction = (
  isGetSampleMediaRequestStarted : boolean
): GetSampleMediaRequestsStartedAction => ({
  isGetSampleMediaRequestStarted,
  type: action_types.GET_SAMPLE_MEDIA_REQUESTS_STARTED  
});

export const getSampleMediaRequestStarted = (
  isGetSampleMediaRequestStarted : boolean
) : GetSampleMediaRequestsStartedAction => {
  return getSampleMediaRequestStartedAction(isGetSampleMediaRequestStarted)
};

export const refreshSampleMediaRequest = (
  mediaRequest: MediaRequest
): ThunkResult<Promise<void>> => {
  return dispatch => {
    dispatch(beginAjaxCall());

    const learningProviderId =  mediaRequest.learningProviderId;
    const mediaSetId = mediaRequest.mediaSetId;
    const headers = { 'Authorization': `Bearer ${auth.getAccessToken()}`, 'x-id-token': `${auth.getIdToken()}`}
    return axios
      .get(`${config.API_GATEWAY.URL}/sample-media-requests/version/${learningProviderId}/${mediaSetId}`, {headers: headers})
      .then(version => {
          const latestVersion: string = version.data;

          // Get version and check if it is different, only get full sample media request if it has changed.
          if (mediaRequest.version !== latestVersion) {
            return axios
              .get(`${config.API_GATEWAY.URL}/sample-media-requests/${learningProviderId}/${mediaSetId}`, {headers: headers})
              .then(response => {
                const sampleMediaRequest = response.data;

                const mr = new MediaRequest();
                      mr.consortiaId = sampleMediaRequest.consortiaId;
                      mr.parentRequests = sampleMediaRequest.parentRequests;
                      mr.academicYear =sampleMediaRequest.academicYear;
                      mr.mediaSetId = sampleMediaRequest.mediaSetId;
                      mr.qualificationId = sampleMediaRequest.qualificationId;
                      mr.qualificationName = sampleMediaRequest.qualificationName;
                      mr.learningProviderId = sampleMediaRequest.learningProviderId;
                      mr.learningProviderCode = sampleMediaRequest.learningProviderCode;
                      mr.learningProviderName = sampleMediaRequest.learningProviderName;
                      mr.parentLearningProviderCode=sampleMediaRequest.parentLearningProviderCode;
                      if(sampleMediaRequest.deadlineDateTime !== null){
                        mr.deadlineDateTime = new Date(sampleMediaRequest.deadlineDateTime);
                      }
                      // mr.deadlineDateTime = new Date(sampleMediaRequest.deadlineDateTime);
                      mr.numberOfLearners = sampleMediaRequest.numberOfLearners;
                      mr.numberOfFiles = sampleMediaRequest.numberOfFiles;
                      mr.standardsVerifierId = sampleMediaRequest.standardsVerifierId;
                      mr.standardsVerifierName = sampleMediaRequest.standardsVerifierName;
                      mr.qualificationGroupCode = sampleMediaRequest.qualificationGroupCode
                      mr.learnerUnits = sampleMediaRequest.learnerUnits;
                      mr.files = sampleMediaRequest.files;
                      mr.status = sampleMediaRequest.status;
                      mr.submissionDate = new Date(sampleMediaRequest.submissionDate);
                      mr.submittedBy = sampleMediaRequest.submittedBy;
                      mr.version = sampleMediaRequest.version;
                      mr.proxyName = sampleMediaRequest.learningProviderCode+ " "+ sampleMediaRequest.learningProviderName;

                dispatch(updateSampleMediaRquestSuccessAction(mr));
              })
              .catch(error => {
                dispatch(ajaxCallError(error));
                throw error;
              });
          }
          else{
            return;
          }
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};

export const countSampleMediaRequest = (
  learningProviderId: string
): ThunkResult<Promise<void>> => {
  const headers = { 'Authorization': `Bearer ${auth.getAccessToken()}`, 'x-id-token': `${auth.getIdToken()}`}
  return dispatch => {
    dispatch(beginAjaxCall());
return axios
      .get(`${config.API_GATEWAY.URL}/sample-media-requests/count/${learningProviderId}`, {headers: headers})
      .then(count => {
          const counts  = count.data.mediaSetsCount;
          
          // Get count and check if it is different, only get full sample media requests if it has changed.
          if (localStorage.getItem('mediaSetsCount') !== counts.toString()) {
            localStorage.setItem('mediaSetsCount',counts)
            
            return axios
            .get(`${config.API_GATEWAY.URL}/sample-media-requests`, {        
              params: {
                learningProviderId 
              },
              headers
            })
            .then(response => {
              const sampleMediaRequests = response.data;
       
        dispatch(
          getSampleMediaRquestsSuccessAction(
            sampleMediaRequests.map((sampleMediaRequestResponse: MediaRequest) => {
              const mr = new MediaRequest();
              mr.consortiaId = sampleMediaRequestResponse.consortiaId;
              mr.parentRequests = sampleMediaRequestResponse.parentRequests;
              mr.academicYear =sampleMediaRequestResponse.academicYear;
              mr.mediaSetId = sampleMediaRequestResponse.mediaSetId;
              mr.qualificationId = sampleMediaRequestResponse.qualificationId;
              mr.qualificationName = sampleMediaRequestResponse.qualificationName;
              mr.learningProviderId = sampleMediaRequestResponse.learningProviderId;
              mr.learningProviderName = sampleMediaRequestResponse.learningProviderName;
              mr.learningProviderCode = sampleMediaRequestResponse.learningProviderCode;
              mr.parentLearningProviderCode=sampleMediaRequestResponse.parentLearningProviderCode;
              if(sampleMediaRequestResponse.deadlineDateTime !== null){
                mr.deadlineDateTime = new Date(sampleMediaRequestResponse.deadlineDateTime);
              }
              // mr.deadlineDateTime = new Date(sampleMediaRequest.deadlineDateTime);
              mr.numberOfLearners = sampleMediaRequestResponse.numberOfLearners;
              mr.numberOfFiles = sampleMediaRequestResponse.numberOfFiles;
              mr.submittedBy = sampleMediaRequestResponse.submittedBy;
              mr.qualificationGroupCode= sampleMediaRequestResponse.qualificationGroupCode;
              mr.standardsVerifierId = sampleMediaRequestResponse.standardsVerifierId;
              mr.standardsVerifierName = sampleMediaRequestResponse.standardsVerifierName;
              mr.learnerUnits = sampleMediaRequestResponse.learnerUnits;
              mr.files = sampleMediaRequestResponse.files;
              mr.status = sampleMediaRequestResponse.status;
              mr.submissionDate = new Date(sampleMediaRequestResponse.submissionDate);
              mr.version = sampleMediaRequestResponse.version;
              mr.proxyName = sampleMediaRequestResponse.learningProviderCode+ " "+ sampleMediaRequestResponse.learningProviderName;

              return mr;
                    })
                  )
                );
              })
              .catch(error => {
                dispatch(ajaxCallError(error));
                throw error;
              });
          }
          else{
            return;
          }
      })
      .catch(error => {
        dispatch(ajaxCallError(error));
        throw error;
      });
  };
};


export const refreshMediaRequest = (
  learningProviderId: string
): ThunkResult<Promise<void>> => {
  const headers = { 'Authorization': `Bearer ${auth.getAccessToken()}`, 'x-id-token': `${auth.getIdToken()}`}
  return dispatch => {
    dispatch(beginAjaxCall());
// return axios
//       .get(`${config.API_GATEWAY.URL}/sample-media-requests/count/${learningProviderId}`)
//       .then(count => {
//           const counts  = count.data.mediaSetsCount;
          
          // Get count and check if it is different, only get full sample media requests if it has changed.
         // if (localStorage.getItem('mediaSetsCount') !== counts.toString()) {
            // localStorage.setItem('mediaSetsCount',counts)
            
            return axios
            .get(`${config.API_GATEWAY.URL}/sample-media-requests`, {        
              params: {
                learningProviderId 
              },
              headers
            })
            .then(response => {
              const sampleMediaRequests = response.data;
       
        dispatch(
          getSampleMediaRquestsSuccessAction(
            sampleMediaRequests.map((sampleMediaRequest: MediaRequest) => {
              const mr = new MediaRequest();
              mr.consortiaId = sampleMediaRequest.consortiaId;
              mr.parentRequests = sampleMediaRequest.parentRequests;
              mr.academicYear =sampleMediaRequest.academicYear;
              mr.mediaSetId = sampleMediaRequest.mediaSetId;
              mr.qualificationId = sampleMediaRequest.qualificationId;
              mr.qualificationName = sampleMediaRequest.qualificationName;
              mr.learningProviderId = sampleMediaRequest.learningProviderId;
              mr.learningProviderName = sampleMediaRequest.learningProviderName;
              mr.parentLearningProviderCode=sampleMediaRequest.parentLearningProviderCode;
              mr.learningProviderCode = sampleMediaRequest.learningProviderCode;
              mr.deadlineDateTime = new Date(sampleMediaRequest.deadlineDateTime);
              mr.numberOfLearners = sampleMediaRequest.numberOfLearners;
              mr.numberOfFiles = sampleMediaRequest.numberOfFiles;
              mr.submittedBy = sampleMediaRequest.submittedBy;
              mr.qualificationGroupCode= sampleMediaRequest.qualificationGroupCode;
              mr.standardsVerifierId = sampleMediaRequest.standardsVerifierId;
              mr.standardsVerifierName = sampleMediaRequest.standardsVerifierName;
              mr.learnerUnits = sampleMediaRequest.learnerUnits;
              mr.files = sampleMediaRequest.files;
              mr.status = sampleMediaRequest.status;
              mr.submissionDate = new Date(sampleMediaRequest.submissionDate);
              mr.version = sampleMediaRequest.version;
              mr.proxyName = sampleMediaRequest.learningProviderCode+ " "+ sampleMediaRequest.learningProviderName;

              return mr;
                    })
                  )
                );
              })
              .catch(error => {
                dispatch(ajaxCallError(error));
                throw error;
              });
          // }
          // else{
          //   return;
          // }
      // })
      // .catch(error => {
      //   dispatch(ajaxCallError(error));
      //   throw error;
      // });
  };
};

export const updateLearners = (
  mediaRequestFiles: LearnerUnit | any,
  mediaRequest: MediaRequest,
  action: string,
  mediaRequestFileSelected : MediaRequestFile
): ThunkResult<Promise<void>> => {
  return dispatch => {
    let params={};
    if (action === 'add-learner'){
      params = {
        fileId : mediaRequestFileSelected.fileId,
        fileKey : mediaRequestFileSelected.fileId,
        fileName : mediaRequestFileSelected.fileName,
        learnerIds : mediaRequestFiles.length ? mediaRequestFiles :  [mediaRequestFiles.learnerId],
        learningProviderId : mediaRequest.learningProviderId,
        mediaSetId : mediaRequest.mediaSetId,
        unitId : mediaRequestFileSelected.unitId
      }
    } else {
      params = {
        fileId : mediaRequestFileSelected.fileId,
        learnerIds : mediaRequestFiles.length ? mediaRequestFiles :  [mediaRequestFiles.learnerId],
        learningProviderId : mediaRequest.learningProviderId,
        mediaSetId : mediaRequest.mediaSetId,
        unitId : mediaRequestFileSelected.unitId
      }
    }
    dispatch(beginAjaxCall());
    
    return axios
      .post(
        `${config.API_GATEWAY.URL}/sample-media-requests/${action}`,
        params,
        { headers: { 
          "content-type": "application/json",
          "Authorization": `Bearer ${auth.getAccessToken()}`,
          'x-id-token': `${auth.getIdToken()}`
      } }
      )
      .then(response => {
          const sampleMediaRequest = response.data && response.data.mediaSet;
          const mr = new MediaRequest;
                mr.consortiaId = sampleMediaRequest.consortiaId;
                mr.parentRequests = sampleMediaRequest.parentRequests;
                mr.mediaSetId = sampleMediaRequest.mediaSetId;
                mr.qualificationId = sampleMediaRequest.qualificationId;
                mr.qualificationName = sampleMediaRequest.qualificationName;
                mr.learningProviderId = sampleMediaRequest.learningProviderId;
                mr.learningProviderCode = sampleMediaRequest.learningProviderCode;
                mr.parentLearningProviderCode=sampleMediaRequest.parentLearningProviderCode;
                mr.learningProviderName = sampleMediaRequest.learningProviderName;
                mr.deadlineDateTime = new Date(sampleMediaRequest.deadlineDateTime);
                mr.numberOfLearners = sampleMediaRequest.numberOfLearners;
                mr.numberOfFiles = sampleMediaRequest.numberOfFiles;
                mr.standardsVerifierId = sampleMediaRequest.standardsVerifierId;
                mr.standardsVerifierName = sampleMediaRequest.standardsVerifierName;
                mr.qualificationGroupCode = sampleMediaRequest.qualificationGroupCode
                mr.learnerUnits = sampleMediaRequest.learnerUnits;
                mr.files = sampleMediaRequest.files;
                mr.status = sampleMediaRequest.status;
                mr.submissionDate = new Date(sampleMediaRequest.submissionDate);
                mr.version = sampleMediaRequest.version;
                mr.proxyName = mediaRequest.learningProviderCode+ " "+ mediaRequest.learningProviderName;
        dispatch(updateSampleMediaRquestSuccessAction(mr));

      }) 
      .catch(error => {
        if(error.response.status === 409){
          dispatch(addLearnerError())
        }
    //    switch(error.response.status) {
         
    //       case 409:
    //         {
    //           dispatch(addLearnerError())
    //         }
    //  }
        throw error;
      });
  };
};


export const closeAddLearnerErrorModal = () : CloseShowAddLearnerErrorModalAction => {
  return  closeShowAddLearnerErrorModal()
};

export const ShowAddLearnerErrorModal = () : AddLearnerErrorAction => {
  return addLearnerError();
};


export const removeFile = (
  learningProviderId : string,
  mediaSetId: string,
  fileId: string
): ThunkResult<Promise<void>> => {

  return dispatch => {
    const params = {
      fileId,
      learningProviderId, 
      mediaSetId
    };
    dispatch(beginAjaxCall());

    return axios
      .post(
        `${config.API_GATEWAY.URL}/sample-media-requests/remove-file`,
        params,
        { headers: { 
          "content-type": "application/json",
          "Authorization": `Bearer ${auth.getAccessToken()}`,
          'x-id-token': `${auth.getIdToken()}`
      } }
      )
      .then(response => {
          const sampleMediaRequest = response.data;
          // console.log("sampleMediaRequest", sampleMediaRequest)
          const mr = new MediaRequest();
                mr.consortiaId = sampleMediaRequest.consortiaId;
                mr.parentRequests = sampleMediaRequest.parentRequests;
                mr.mediaSetId = sampleMediaRequest.mediaSetId;
                mr.qualificationId = sampleMediaRequest.qualificationId;
                mr.qualificationName = sampleMediaRequest.qualificationName;
                mr.learningProviderId = sampleMediaRequest.learningProviderId;
                mr.learningProviderCode = sampleMediaRequest.learningProviderCode;
                mr.learningProviderName = sampleMediaRequest.learningProviderName;
                mr.parentLearningProviderCode=sampleMediaRequest.parentLearningProviderCode;
                mr.deadlineDateTime = new Date(sampleMediaRequest.deadlineDateTime);
                mr.numberOfLearners = sampleMediaRequest.numberOfLearners;
                mr.numberOfFiles = sampleMediaRequest.numberOfFiles;
                mr.standardsVerifierId = sampleMediaRequest.standardsVerifierId;
                mr.standardsVerifierName = sampleMediaRequest.standardsVerifierName;
                mr.qualificationGroupCode = sampleMediaRequest.qualificationGroupCode
                mr.learnerUnits = sampleMediaRequest.learnerUnits;
                mr.files = sampleMediaRequest.files;
                mr.status = sampleMediaRequest.status;
                mr.submissionDate = new Date(sampleMediaRequest.submissionDate);
                mr.version = sampleMediaRequest.version;
                mr.proxyName = sampleMediaRequest.learningProviderCode+ " "+ sampleMediaRequest.learningProviderName;
        dispatch(updateSampleMediaRquestSuccessAction(mr));
      })
      .catch(error => {
        // console.log("ACTION ERROR!!!!!!!!!!!!", error);
        dispatch(ajaxCallError(error));
        throw error;
      });
  }
}