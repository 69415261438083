import * as React from 'react';
import config from '../../app-config';




export const Footer = () => (
  <div className="footer">
      <ul className="links">
            <li className="terms"><a href={config.HELP_LINKS.TERMS_OF_USE} target="_blank">Terms of Use</a></li>
            <li className="privacy"><a href={config.HELP_LINKS.PRIVACY_POLICY} target="_blank">Privacy Policy</a></li>
            
        </ul>
        <p className="copyright">Copyright © 1996-{new Date().getFullYear()} Pearson Education Inc. All Rights Reserved.</p>
    
  </div>
);

export default Footer;