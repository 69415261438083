import { connect} from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  AssessmentAssociateAvailability,
  StateProps,
  DispatchProps
} from './assessment-associate-availability';
import {
  getAssessmentAssociate,
  AssessmentAssociateAction
} from '../../actions/assessment-associate.actions';
import { StoreState } from '../../store/store-state';

const mapStateToProps = (state: StoreState): StateProps => ({
  assessmentAssociate: state.assessmentAssociate
});

const mapDispatchToProps = (dispatch: ThunkDispatch<StoreState, void, AssessmentAssociateAction>): DispatchProps => ({
  getAssessmentAssociate: (aaNumber: string) =>
    dispatch(getAssessmentAssociate(aaNumber))
});

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(AssessmentAssociateAvailability);
