import { Reducer } from 'redux';
import initialState from './initialState';
import { LearningProvider } from '../models/learning-provider'
import { UserDetailsAction } from '../actions/user-details-by-forgerock.actions';

import { AuthAction } from '../actions/auth/auth.actions';
import { GET_USER_DETAILS_BY_FORGEROCK_REQUESTS_SUCCESS, UPDATE_SELECTED_USER_ID } from '../actions/action-types';


export const learningProviderReducer: Reducer<LearningProvider> = (
  state: LearningProvider = initialState.learningProvider,
  action: any | AuthAction | UserDetailsAction
) => {

  if (action.type === GET_USER_DETAILS_BY_FORGEROCK_REQUESTS_SUCCESS) {
    const selectedUserId = action.getStates && action.getStates.selectedUserId;
    const associatedLearningProviders = action.userDetailsByForgerock && action.userDetailsByForgerock.associatedLearningProviders;
    const userNotSelected = selectedUserId === "0" || selectedUserId === null;
    let selectedLearningProvider;

    if(associatedLearningProviders)
    {
      associatedLearningProviders.map((users : any) =>{
      const learningProviderObj =   { id:  users.learningProvider.learningProviderId, code: users.learningProvider.vqLearningProviderCode,
            name: users.learningProvider.learningProviderName };
        if(users.learningProvider && users.learningProvider.learningProviderId.toString() === localStorage.getItem("selectedUserId")!){
          selectedLearningProvider = learningProviderObj;
        } else if(userNotSelected && associatedLearningProviders.length === 1) {
          selectedLearningProvider = learningProviderObj;
        } else if(userNotSelected && associatedLearningProviders.length > 1) {
          selectedLearningProvider = { id:  action.userDetailsByForgerock.userId, code: "100"+action.userDetailsByForgerock.userId,
            name: "" };
        }
      })
    }
    return selectedLearningProvider ? selectedLearningProvider : state;
  }else{
   
      return state;
  }

  // default LP
  // switch (action.type) {
  //   case GET_USER_DETAILS_BY_FORGEROCK_REQUESTS_SUCCESS:
  //   const selectedUserId = action.getStates && action.getStates.selectedUserId;
  //   const associatedLearningProviders = action.userDetailsByForgerock && action.userDetailsByForgerock.associatedLearningProviders;
  //   const userNotSelected = selectedUserId === "0" || selectedUserId === null;
  //   let selectedLearningProvider;
  //   if(associatedLearningProviders)
  //   {
  //     associatedLearningProviders.map((users : any) =>{
  //     const learningProviderObj =   { id:  users.learningProvider.learningProviderId, code: users.learningProvider.vqLearningProviderCode,
  //           name: users.learningProvider.learningProviderName };
  //       if(users.learningProvider && users.learningProvider.learningProviderId.toString() === localStorage.getItem("selectedUserId")!){
  //         selectedLearningProvider = learningProviderObj;
  //       } else if(userNotSelected && associatedLearningProviders.length === 1) {
  //         selectedLearningProvider = learningProviderObj;
  //       } else if(userNotSelected && associatedLearningProviders.length > 1) {
  //         selectedLearningProvider = { id:  action.userDetailsByForgerock.userId, code: "100"+action.userDetailsByForgerock.userId,
  //           name: "" };
  //       }
  //     })
  //   }
  //   return selectedLearningProvider ? selectedLearningProvider : state;
    
  //   default:
  //     return state;
  // }
};

export const selectedUserReducer: Reducer<string> = (
  state: string = initialState.selectedUserId,
  action: UserDetailsAction
) => {
  if(action.type === UPDATE_SELECTED_USER_ID){
    return action.selectedUserId;
  }else{
    return state;
  }
  // switch (action.type) {
  //   case UPDATE_SELECTED_USER_ID:
  //       return action.selectedUserId;
   
  //   default:
  //     return state;
  // }
};
