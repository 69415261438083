import * as React from 'react';
import { MediaRequestFile } from "../../../models/media-request";

interface Props {
    mediaRequestFile: MediaRequestFile;
    render:() => JSX.Element;
}

interface State {
    showMenu: boolean;
}

class ThreeDotsMenu extends React.Component<Props, State> {
  dropdownMenu: HTMLDivElement | null;
  constructor(props:Props) {
    super(props);
    
    this.state = {
      showMenu: false,
    };    

    this.showMenuClick = this.showMenuClick.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }
  
  showMenuClick() {
    this.setState({ showMenu: true });
      document.addEventListener('click', event => {this.closeMenu});
    };
  
  
  closeMenu() {
    // if (this.dropdownMenu && !this.dropdownMenu.contains(event.target)) {
      
      this.setState({ showMenu: false }, () => {
        document.removeEventListener('click', this.closeMenu);
      });  
      
    // }
  }

  render() {
    return (
      <div className="table-menu">
        <div className="vdots" onClick={this.state.showMenu ? this.closeMenu : this.showMenuClick} />     
        
        {
          this.state.showMenu
            ? (
            <div className="bubble">
              <div
                className="link-holder"                 
                ref={(element) => {
                  this.dropdownMenu = element;
                }}
              >
                {/* <button className="menu-item"> Menu item 1 </button><br /><hr />
                <button className="menu-item"> Menu item 2 </button><br /><hr />
                <button className="menu-item"> Menu item 3 </button><br /> */}
                {this.props.render()}
              </div>
            </div>
            )
            : (
              null
            )
        }
      </div>
    );
  }
}

export default React.memo(ThreeDotsMenu);