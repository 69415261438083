import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import * as  _ from 'lodash';

import {
  SampleMediaRequestsLearners,
  StateProps,
  DispatchProps,
  OwnProps
} from './sample-media-request-learners.component';

import {
  MediaUploadActions,
  uploadMedia
} from '../../../actions/media-upload.actions';

import {
  MediaSetAction
} from '../../../actions/media-set.actions';

import {
  getMediaSetsByLearningProviderCode,
  getSampleMediaRquestsSuccessAction,
  
} from '../../../actions/select-learning-provider.actions';

import { 
  SampleMediaRequestsAction, 
  getSampleMediaRequests, 
  refreshSampleMediaRequest, 
  updateLearners, 
  closeAddLearnerErrorModal,
  removeFile, 
  ShowAddLearnerErrorModal
} from '../shared/sample-media-request.actions';
import { StoreState } from '../../../store/store-state';
import { MediaRequest, MediaRequestFile } from '../../../models/media-request';
import { MediaUploadFile } from '../../../models/media-upload';
import { submitMediaSet, removeFileFromMediaSet } from '../../../actions/media-set.actions';
import { LearnerUnit } from '../../../models/LearnerUnit';
import { GlobalAlertListActions, addGlobalAlertAction } from "../../global-alert-list/shared/global-alert-list.actions";
import { GlobalAlertMessage } from "../../../models/global-alert-message";
import { Auth } from "../../../models/auth";

const mapStateToProps = (state: StoreState, ownProps: OwnProps): StateProps => {
  const sampleMediaRequest = _.find(state.sampleMediaRequests, {
    mediaSetId: ownProps.match.params.sampleMediaRequestId
  }) as MediaRequest;
  const auth = state.auth as Auth;
  const showAddLearnerError = state.showAddLearnerError
  return {
    learningProviderId: state.learningProvider.id,
    sampleMediaRequest,
    mediaUploadStatus: state.mediaUploadStatus,
    auth,
    showAddLearnerError
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    StoreState,
    void,
    SampleMediaRequestsAction | MediaUploadActions | MediaSetAction | GlobalAlertListActions | getSampleMediaRquestsSuccessAction
  >
): DispatchProps => ({
  upload: (mediaUploadFile: MediaUploadFile) =>  dispatch(uploadMedia(mediaUploadFile)),
  submitMediaRequest: (mediaRequest: MediaRequest, mediaSetSubmittedBy: string) => dispatch(submitMediaSet(mediaRequest, mediaSetSubmittedBy)),
  removeFileFromLearner: (mediaRequest:MediaRequest, file:MediaRequestFile, learner:LearnerUnit) => dispatch(removeFileFromMediaSet(mediaRequest, file, learner)),
  raiseAlert: (globalAlertMessage: GlobalAlertMessage) => dispatch(addGlobalAlertAction(globalAlertMessage)),
  getSampleMediaRequests: (learningProviderId: string) => dispatch(getSampleMediaRequests(learningProviderId)),
  refreshSampleMediaRequest: (mediaRequest: MediaRequest) => dispatch(refreshSampleMediaRequest(mediaRequest)),
  getMediaSetsByLearningProviderCode:learningProviderCode =>
    dispatch(getMediaSetsByLearningProviderCode(learningProviderCode)),
  updateLearners:( mediaRequestFiles: LearnerUnit | any,
    sampleMediaRequest: MediaRequest, action: string, mediaRequestFileSelected: MediaRequestFile) => dispatch(updateLearners(mediaRequestFiles,sampleMediaRequest,action, mediaRequestFileSelected)),
  removeFile: (
    learningProviderId : string,
    mediaSetId: string,
    fileId: string) => dispatch(removeFile(learningProviderId, mediaSetId, fileId)),
  closeAddLearnerErrorModal: () => dispatch(closeAddLearnerErrorModal()),
  showAddLearnerErrorModal: () => dispatch(ShowAddLearnerErrorModal())
});

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(SampleMediaRequestsLearners);
