import { Action } from "redux";
import * as action_types from '../../../actions/action-types';
import { GlobalAlertMessage } from '../../../models/global-alert-message';

interface AddGlobalAlertAction extends Action {
    type: action_types.ADD_GLOBAL_ALERT;
    status: GlobalAlertMessage;
}

interface RemoveGlobalAlertAction extends Action {
    type: action_types.REMOVE_GLOBAL_ALERT;
    status: GlobalAlertMessage;
}

export type GlobalAlertListActions =
  | AddGlobalAlertAction
  | RemoveGlobalAlertAction;

export const addGlobalAlertAction = (
    status: GlobalAlertMessage
): AddGlobalAlertAction => ({
    type: action_types.ADD_GLOBAL_ALERT,
    status
});

export const removeGlobalAlertAction = (
    status: GlobalAlertMessage
): RemoveGlobalAlertAction => ({
    type: action_types.REMOVE_GLOBAL_ALERT,
    status
});




