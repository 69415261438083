import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import _ from "lodash";
import { MediaUploadStatus } from "../../models/media-upload";
// import uploadingImage from "./upload-24.svg";

import { Button } from 'ukas.uux.react-components';

require("@webcomponents/webcomponentsjs/webcomponents-bundle");
require("@webcomponents/webcomponentsjs/custom-elements-es5-adapter");
require("@pearson-ux/drawer/js/dist/pearson-drawer");
require("@pearson-ux/progress-bar/js/dist/progress-bar");
require("@pearson-ux/alert/js/dist/pearson-alert");

export interface StateProps {
  mediaUploadStatus: MediaUploadStatus[] | null;
}

export type OwnProps = RouteComponentProps<{}>;

type Props = OwnProps & StateProps;

interface LocalState {
  drawerIsOpen: boolean | undefined;
  drawerText: any;
  maxConcurrentUploads: number;
  currentUploads: number;
}

export class MediaUpload extends React.Component<Props, LocalState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      drawerIsOpen: undefined,
      drawerText: {
        headerTitle: "Uploads",
        closeButtonSRText: "Close",
        backButtonText: "Back"
      },
      maxConcurrentUploads: 1,
      currentUploads: 0
    };

    this.showUploads = this.showUploads.bind(this);
    this.cancelAllUploads = this.cancelAllUploads.bind(this);
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.mediaUploadStatus && nextProps.mediaUploadStatus.length > 0 && localStorage.getItem("cancelUpload") === "true") {
      this.cancelAllUploads(nextProps.mediaUploadStatus)
      localStorage.setItem("cancelUpload", "false");
    }
  }

  render() {
    return (
      <div>
        {this.createUploadButton()}
        <pearson-drawer
          id="pearsonDrawer"
          open={this.state.drawerIsOpen}
          drawerIsOpen={this.state.drawerIsOpen}
        >
          <h3 slot="title" className="title">
            Uploads
          </h3>

          <div slot="content" className="content">
            {this.props.mediaUploadStatus && this.props.mediaUploadStatus.length > 0 &&
              <div>
                <Button
                  id="cancelAllUploads"
                  onClick={this.cancelAllUploads.bind(this, this.props.mediaUploadStatus)}
                  variant="secondary">Cancel all uploads
                </Button>
                <br /><br />
              </div>
            }

            {this.createUploadRows()}
          </div>
        </pearson-drawer>
      </div>
    );
  }

  shouldComponentUpdate(nextProps: Props) {
    if (nextProps.mediaUploadStatus && nextProps.mediaUploadStatus.length > 0) {
      const inProgress = nextProps.mediaUploadStatus.filter(
        (m: MediaUploadStatus) => m.inProgress
      );

      if (inProgress.length === 0) {
        const uploadsInProgress = inProgress.length;
        if (this.state.currentUploads < this.state.maxConcurrentUploads) {
          nextProps.mediaUploadStatus[0].startUpload();

          this.setState({ currentUploads: uploadsInProgress });
        }
      }
    }

    return true;
  }

  createUploadButton() {
    const uploadDivStyle: React.CSSProperties = {
      display: "block",
      position: "fixed",
      height: "70px",
      width: "70px",
      right: "0",
      top: "220px",
      bottom: "0",
      // backgroundColor: "#f5f5f5",
      padding: "5px"
    };

    if (
      this.props.mediaUploadStatus &&
      this.props.mediaUploadStatus.length > 0
    ) {
      return (
        <div
          onClick={this.showUploads}
          style={uploadDivStyle}
          className="uploadicontooltip"
        >
          <span className="tooltiptext">Click here to see the progress of your upload(s).</span>
          <i className="fas fa-file-upload" />
          {/* <img src={uploadingImage} width="70px" /> */}
        </div>
      );
    }
    return <div>&nbsp;</div>;
  }

  createUploadRows() {
    if (this.props.mediaUploadStatus && this.props.mediaUploadStatus.length > 0) {
      const rows: any[] = [];

      this.props.mediaUploadStatus.forEach((status, i) => {
        rows.push(
          <div key={"progress" + i}>
            <div>
              <div
                style={{
                  borderStyle: "solid",
                  borderWidth: "2px",
                  borderColor: "grey",
                  padding: "15px"
                }}
              >
                <pearson-progress-bar
                  id="loading"
                  type="loading"
                  progress={this.calculatePercentage(status.percentage)}
                />
                <span>
                  {status.fileName} uploading(
                  {this.calculatePercentage(status.percentage)}%)
                </span>
                <div>&nbsp;</div>
                <div>
                  <Button
                    id="cancelUpload"
                    onClick={status.cancelUpload}
                    variant="secondary"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      });

      return rows;
    }
    return <div>No current uploads in progress.</div>;
  }

  showUploads = () => {
    this.setState({ drawerIsOpen: !this.state.drawerIsOpen });
  };

  calculatePercentage = (decimal: number): number => {
    const inflated = decimal * 100;
    return Number(inflated.toFixed(0));
  };

  cancelAllUploads = (mediaUploads: MediaUploadStatus[]) => {
    mediaUploads.forEach((status, i) => {
      status.cancelUpload();
    });
  };
}

export default MediaUpload;
