
import config from '../../app-config';

export const params = {
  baseUrl: config.FORGEROCK.AUTHORIZE_ENDPOINT.split('/oauth2')[0],
  providerID: 'PearsonForgeRock',
  redirect_uri: config.FORGEROCK.REDIRECT_URI,   
  authorization: config.FORGEROCK.AUTHORIZE_ENDPOINT,
  sessionEndEndpoint: config.FORGEROCK.SESSION_END_ENDPOINT, 
  client_id: config.FORGEROCK.CLIENT_ID, 
  scopes: {
    request: config.FORGEROCK.SCOPES
  },
  request: {
    code_challenge: null,
    code_challenge_method: "S256"
  },
  response_type: 'code',
  token: config.FORGEROCK.TOKEN_ENDPOINT,
}