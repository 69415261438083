export const AVATS_STATUS_INFECTED = 'INFECTED';
export const AVATS_STATUS_REJECTED = 'REJECTED';
export const AVATS_STATUS_INFECTED_TEXT = 'This file is infected with a virus and must be removed';

export const AVATS_STATUS_UNSUPPORTED = 'UNSUPPORTED';
export const AVATS_STATUS_UNSUPPORTED_TEXT = 'This is an unsupported file format and must be removed';

export const AVATS_STATUS_PASSWORD_PROTECTED = 'PASSWORD PROTECTED';
export const AVATS_STATUS_PASSWORD_PROTECTED_TEXT = 'This file or files within this compression file, are password protected and must be removed.';
export const AVATS_STATUS_PASSWORD_PROTECTED_TEXT_SINGLE = 'This file is password protected and must be removed.';
// export const AVATS_STATUS_PASSWORD_PROTECTED_TEXT_COMPRESSED = 'One or more files within this compression file, are password protected and must be removed.';

export const AVATS_STATUS_DEFAULT_TEXT = 'There was a unexpected error, the file cannot be uploaded and must be removed.';

export const AVATS_STATUS_INFECTED_ZIP_TEXT = 'Within this compression file, [X] file(s) are infected with a virus.  The compression file must be removed from the sample request and the problem files replaced with versions that will pass security and virus checks.'

export const STANDARDS_VERIFIER_REJECT_TEXT = "The Standards Verifier has rejected this sample.  Please make the necessary amendments and resubmit it as soon as possible.";

export const AVATS_STATUS_REJECTED_TEXT = "One or more files have been rejected.  Please make the necessary amendments before submitting";

export enum browserVersions {
    CHROME = 76,
    FIREFOX = 69,
    SAFARI = 12,
    EDGECHROMIUM = 80
}
