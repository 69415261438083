import * as React from 'react';

class NotFound extends React.Component<{}, {}> {
  constructor(props: {}) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className="pe-template__single">
          <h1 className="pe-page-title">Learner Work Transfer</h1>
        </div>
        <div className="pe-template__single content">
        <p>
          Page not found.
        </p>
        </div>
      </div>
    );
  }
}

export default NotFound;
