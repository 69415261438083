import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { App, AppProps, DispatchProps } from './app';
import { StoreState } from '../store/store-state';
import {
  signinUser,
  signoutUser,
  AuthAction
} from '../actions/auth/auth.actions';
import {
  getSampleMediaRequests,
  SampleMediaRequestsAction,
  getSampleMediaRequestStarted,
  getSampleMediaRequestCompleted
} from '../features/sample-media-request/shared/sample-media-request.actions';

import {
  getMediaSetsByLearningProviderCode,
  getSampleMediaRquestsSuccessAction,
  
} from '../actions/select-learning-provider.actions';

import {
  getUserDetailsRequestByForgerock,validateUserPermissions, UserDetailsAction, getEnv, updateSelectedUserId,
  
}
from '../actions/user-details-by-forgerock.actions'


const mapStateToProps = (state: StoreState): AppProps => ({
  learningProviderId: state.learningProvider.id,
  loading: state.ajaxCallsInProgress > 0,
  isAuthenticated: state.auth.authenticated,
  profile: state.auth.profile,
  mediaRequest:state.sampleMediaRequests,
  proxyName:state.auth.proxyName,
  proxyId:state.auth.proxyId,
  userDetailsByForgerock : state.userDetailsByForgerock,
  userPermissionValidated: state.userDetailsByForgerock.isUserPermissionValid,
  showCreateMenu: state.showCreateMenu,   
  selectedUserId: state.selectedUserId,
  isSessionAlive: state.isSessionAlive
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    StoreState,
    void,
    SampleMediaRequestsAction |getSampleMediaRquestsSuccessAction | UserDetailsAction |AuthAction 
  >
): DispatchProps => ({
  signin: () => signinUser(dispatch),
  signout: () => signoutUser(dispatch),
  loadSampleMediaRequests: learningProviderId =>
    dispatch(getSampleMediaRequests(learningProviderId)),
  setSelectedUserId: userId => 
    dispatch(updateSelectedUserId(userId)),
  userDetailsRequests : forgeRockId => 
      dispatch (getUserDetailsRequestByForgerock(forgeRockId)),
  loadSampleMediaRequestsByLearningProviderCode:learningProviderCode =>
    dispatch(getMediaSetsByLearningProviderCode(learningProviderCode)),
  validateUserPermissions:(family_name, loggedInUserPermissionIds) => 
    dispatch(validateUserPermissions(family_name, loggedInUserPermissionIds)),
  getEDetails: () => dispatch(getEnv()),
  getSampleMediaRequestStarted: status => dispatch(getSampleMediaRequestStarted(status)),
  getSampleMediaRquestsSuccessAction: requests => dispatch(getSampleMediaRquestsSuccessAction(requests)),
  getSampleMediaRequestCompleted: status => dispatch(getSampleMediaRequestCompleted(status)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
