import * as React from 'react';

class Maintain extends React.Component<{}, {}> {
  constructor(props: {}) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className="pe-template__single">
          <h1 className="pe-page-title">Learner Work Transfer</h1>
        </div>
        <div className="pe-template__single content">
        <h1>We&rsquo;ll be back soon!</h1><br />
        <p style={{fontSize:"1.2rem"}}>Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment. We&rsquo;ll be back online shortly!</p>
        </div>
      </div>
    );
  }
}

export default Maintain;
