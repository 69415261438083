import * as React from "react";
import _ from "lodash";
import { RouteComponentProps } from "react-router-dom";

import { MediaRequest } from "../../../models/media-request";
import SampleMediaRequestsList from "./shared/sample-media-requests-list";
import { MediaUploadFile } from "../../../models/media-upload";
import { MediaUploadStatus } from "../../../models/media-upload";
import moment from "moment";
import TextHelper from "../shared/text-formatting";
const pageTitle = "Sample Requests";

const PearsonElementsSdk = require("@pearson-components/modal");
// const PearsonElementsSDK = require("@pearson-components/elements-sdk/build/dist.elements-sdk");
import SelectLearningProvider from "../../../components/select-learning-provider/select-learning-provider.container";
// import ErrorAlert from '../../../components/common/error-alert';
import { userTypeEnums} from '../shared/enum';
import { AuthProfile } from 'models/auth';
import loading from '../../../components/callback/loading.svg';
import { Alert } from "ukas.uux.react-components";

export interface StateProps {
  learningProviderId: string;
  userPermissionValidated: boolean;
  sampleMediaRequests: MediaRequest[];
  mediaUploadStatus: MediaUploadStatus[] | null;
  profile?: AuthProfile;
  isGetSampleMediaRequestCompleted: boolean;
  isGetSampleMediaRequestStarted: boolean;
}

export interface DispatchProps {
  getSampleMediaRequests: (learningProviderId: string) => Promise<void>;
  getSampleMediaRequestCompleted: (status: boolean)  => void;
  upload: (uploadFile: MediaUploadFile) => Promise<void>;
  refreshSampleMediaRequest: (mediaRequest: MediaRequest) => Promise<void>;
  countSampleMediaRequest: (learningProviderId: string) => Promise<void>;
  refreshMediaRequest: (learningProviderId: string) => Promise<void>;
  getMediaSetsByLearningProviderCode: (learningProviderCode: string) => Promise<void>;
}

export type OwnProps = RouteComponentProps<{}>; 

interface Props extends DispatchProps, StateProps, OwnProps {}

interface State {
  isBusy: boolean;
  selectedColumn: string;
  direction: string;
  searchText: string;
  refreshTimer: NodeJS.Timeout;
  // countTimer: NodeJS.Timeout;
  showSearchModal: boolean;
  submissionError: boolean;
  loading: boolean,
  year:string
}

export class SampleMediaRequests extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    document.title = pageTitle;
    this.state = {
      isBusy: false,
      selectedColumn: "",
      direction: "",
      searchText: "",
      refreshTimer: null as unknown as NodeJS.Timeout,
      // countTimer: setInterval(() => this.countMediaRequest(), 300000),
      showSearchModal: false,
      submissionError: false,
      loading: true,
      year:'',
    };

    this.onSelect = this.onSelect.bind(this);
    this.getSampleMediaRequestById = this.getSampleMediaRequestById.bind(this);
    this.handleColumnSort = this.handleColumnSort.bind(this);
    this.getIconName = this.getIconName.bind(this);
    this.sort = this.sort.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.refreshRequest = this.refreshRequest.bind(this);
    this.onSubmissionErrorAlertClose = this.onSubmissionErrorAlertClose.bind(
      this
    );
    this.sampleMediaRequestsByYear = this.sampleMediaRequestsByYear.bind(this);
    this.onYearChange = this.onYearChange.bind(this)
  }

  render() {
    const { isBusy, selectedColumn, submissionError } = this.state;
    const text = {
      headerTitle: "Select a Learning Provider",
      bodyText: "",
      closeButtonSRText: "Close",
      modalSaveButtonText: "Ok",
      modalCancelButtonText: "Close"
    };
    const userType = this.props.profile
      ? this.props.profile.family_name
      : "";
      const style = {
        backgroundColor: 'white',
        bottom: 0,
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        left: 0,
        position: 'absolute' as 'absolute',
        right: 0,
        top: 0,
        width: '100vw'
      };
      let displaySelect = true;
      if(!this.props.isGetSampleMediaRequestCompleted && this.props.isGetSampleMediaRequestStarted){
        displaySelect = false;
      }else{
        displaySelect = true;
      }
      // let displayRequest = true;
      if((submissionError && localStorage.getItem("userType") && userType && userType.toUpperCase() === userTypeEnums.INTERNAL_USER.toUpperCase()) || (localStorage.getItem("proxyLpId") === "0" && userType && userType.toUpperCase() === userTypeEnums.EXTERNAL_USER.toUpperCase() && !this.props.isGetSampleMediaRequestStarted))
      {
        displaySelect=false;
      }
    return (
      <div className="content">
        <div className="pe-template__single">
          <h1 className="pe-page-title">{pageTitle}</h1>
        </div>
        <div className="pe-template__single">
          <p>
            This screen shows the sample requests for your Learning Provider.
            Please select the required qualification from the table below to
            upload learner material.
          </p>
          <section>
            { !this.props.isGetSampleMediaRequestCompleted && 
            this.props.isGetSampleMediaRequestStarted && 
            <div style={style}>
              <img src={loading} alt="loading" style={{width:"145px"}}/>
            </div>
            }
            {this.props.sampleMediaRequests !== undefined &&
              this.props.sampleMediaRequests.length > 0 && (
                <SampleMediaRequestsList
                  sampleMediaRequests={
                    selectedColumn === ""
                      ? this.filter(
                          this.sampleMediaRequestsByYear(
                            this.props.sampleMediaRequests
                          )
                        )
                      : this.sort(
                          this.sampleMediaRequestsByYear(
                            this.props.sampleMediaRequests
                          )
                        )
                  }
                  onSelect={this.onSelect}
                  onSort={this.handleColumnSort}
                  getIconName={this.getIconName}
                  onFieldChange={this.onFieldChange}
                  onYearChange={this.onYearChange}
                  displaySelect = {displaySelect}
                />
              )}
            {this.props.sampleMediaRequests.length === 0 &&
              isBusy &&
              "Loading ..."}
          </section>
        </div>
        <div className="searchModal">
          <PearsonElementsSdk.Modal
            id="search"
            isShown={this.state.showSearchModal}
            text={text}
            footerVisible={true}
            cancelBtnHandler={this.cancelModalHandler}
            successBtnHandler={this.successModalHandler}
            saveBtnId="disableSuccessBtn"
          >
            <SelectLearningProvider
              {...(this.props as any)}
              cancelBtnHandler={this.cancelModalHandler}
            />
          </PearsonElementsSdk.Modal>
        </div>
        
        {submissionError  && localStorage.getItem("userType") && userType && userType.toUpperCase() === userTypeEnums.INTERNAL_USER.toUpperCase() && (
          <Alert variant="danger">You must select a learning provider.</Alert>
        )}
     {
        localStorage.getItem("proxyLpId") === "0" && userType && userType.toUpperCase() === userTypeEnums.EXTERNAL_USER.toUpperCase() && !this.props.isGetSampleMediaRequestStarted && (
          <Alert variant="danger">You must select a learning provider.</Alert>
        ) 
      }
      {
      localStorage.getItem("proxyLpId") !== null && this.props.sampleMediaRequests.length === 0 &&
      this.props.isGetSampleMediaRequestCompleted &&
      (
        <Alert variant="danger">There are no sample requests.</Alert>
        ) 
        }
        
      </div>
    );
  }

  componentDidUpdate() {
    if (this.props.userPermissionValidated === false) {
      this.props.history.push("/home");
    }
  }
  componentDidMount() {
    if(this.props.userPermissionValidated === false) {
      this.props.history.push('/home')
    }

    //set media set refresh interval
    const interval = setInterval(()=> this.refreshRequest(), 20000);
    this.setState({refreshTimer: interval});

    this.handleColumnSort("qualificationName");

    // this.setState({showSearchModal:_.includes(this.props.match.path,'select-a-learning-provider')});
    this.setState({
      showSearchModal: _.includes(
        window.location.pathname,
        "select-a-learning-provider"
      )
    });
    localStorage.getItem("proxyName") === null ||
    localStorage.getItem("proxyName") === ""
      ? this.props.sampleMediaRequests.length === 0
        ? this.setState({ submissionError: true })
        : this.setState({ submissionError: false })
      : this.setState({ submissionError: false });

      // this.props.refreshMediaRequest(this.props.learningProviderId);
  }

  componentWillUnmount() {
    //clear media set refresh interval
    clearInterval(this.state.refreshTimer);
    // clearInterval(this.state.countTimer);
  }

  cancelModalHandler = () => {
    this.setState({ showSearchModal: false });
    return this.props.history.push("/sample-requests");
  };
  successModalHandler = () => {
    this.setState({ showSearchModal: false });
    return this.props.history.push("/");
  };

  onSubmissionErrorAlertClose() {
    return this.setState({ submissionError: false });
  }

  onSelect(mediaRequestId: string, event: Event) {
    event.preventDefault();
    const sampleMediaRequest = this.getSampleMediaRequestById(
      this.props.sampleMediaRequests,
      mediaRequestId
    ) as MediaRequest;

    this.props.history.push(
      `/sample-requests/${sampleMediaRequest.mediaSetId}`
    );
  }

  getIconName = (selectedColumn: string) => {
    if (this.state.selectedColumn === selectedColumn) {
      return this.state.direction === "up" ? "sort-up-18" : "sort-down-18";
    }

    return "sortable-18";
  };

  handleColumnSort = (selectedColumn: string) => {
    return () => {
      let direction = "down";

      if (this.state.selectedColumn === selectedColumn) {
        direction = this.state.direction === "up" ? "down" : "up";
      }

      this.setState({ selectedColumn, direction });
    };
  };
  sampleMediaRequestsByYear(
    sampleMediaRequests: MediaRequest[]
  ): MediaRequest[] {
    const getYear = localStorage.getItem("selectedYear");
    if (getYear !== "") {
      const sampleRequests = JSON.parse(JSON.stringify(sampleMediaRequests));
      return _.filter(sampleRequests, item => item.academicYear === getYear);
    }
    
    return sampleMediaRequests;
  }
  filter(sampleMediaRequests: MediaRequest[]): MediaRequest[] {
        const searchTerm = this.state.searchText;
        if (searchTerm !== '' && searchTerm.length >= 3) {
            const mediaRequests = JSON.parse(JSON.stringify(sampleMediaRequests));
            const columnNames = ['standardsVerifierName', 'standardsVerifierId','qualificationGroupCode', 'qualificationName', 'deadlineDateTime', 'numberOfLearners', 'status'];
            return _.filter(mediaRequests, mediaRequest => {
                mediaRequest.status = TextHelper.titleCaseUploadStatus(mediaRequest.status);
                if(mediaRequest && (mediaRequest.deadlineDateTime || mediaRequest.deadlineDateTime !== undefined)) {
                  mediaRequest.deadlineDateTime = moment(new Date(mediaRequest.deadlineDateTime)).format('DD MMM YYYY');
                }
                return _(mediaRequest).pick(columnNames).some(searchstring => {
                    return _(searchstring).toLower().includes(searchTerm);
                });
            }) as MediaRequest[];
        }
        return sampleMediaRequests;
    }

  sort(sampleMediaRequests: MediaRequest[]): MediaRequest[] {
    const lodashDirection = this.state.direction === "down" ? "desc" : "asc";
    const sortedSampleMediaRequests = _.orderBy(
      sampleMediaRequests,
      [
        mediaRequest => {
          if (
            mediaRequest[this.state.selectedColumn] != null &&
            typeof mediaRequest[this.state.selectedColumn].toLowerCase ===
              "function"
          ) {
            return mediaRequest[this.state.selectedColumn].toLowerCase();
          } else {
            return mediaRequest[this.state.selectedColumn];
          }
        }
      ],
      [lodashDirection]
    ) as MediaRequest[];
    return this.filter(sortedSampleMediaRequests);
  }

  getSampleMediaRequestById(sampleMediaRequests: MediaRequest[], id: string) {
    const sampleMediaRequest = sampleMediaRequests.filter(
      (mr: MediaRequest) => mr.mediaSetId === id
    );
    if (sampleMediaRequest.length) {
      return sampleMediaRequest[0];
    }
    return null;
  }

  onYearChange(event: React.FormEvent<HTMLSelectElement>) {
    event.preventDefault();
    const element = event.target as HTMLInputElement;
    const value = element.value;
    const valueSplit = value.split("(");
    const valueSplit1 = valueSplit[1].split("/");
    
    const getYear = valueSplit1[0];
    this.setState({year:getYear})
    localStorage.setItem("selectedYear", getYear);
  }

  onFieldChange(event: React.FormEvent<HTMLInputElement>) {
    event.preventDefault();
    const element = event.target as HTMLInputElement;
    return this.setState({ searchText: element.value.toLowerCase() });
  }

  countMediaRequest() {
    if (this.props.sampleMediaRequests) {
      this.props.countSampleMediaRequest(this.props.sampleMediaRequests && this.props.sampleMediaRequests[0].learningProviderId);
    }
  }

  refreshRequest() {
    // if (this.props.sampleMediaRequests) {
    //   const sampleMediaRequestStatus = ['REQUEST_RECALLED','UPLOAD_SUBMITTED','SUBMIT_PENDING','UPLOAD_IN_PROGRESS','AWAITING_UPLOAD'];
    //  const refreshSampleMediaRequest = this.props.sampleMediaRequests.filter((f: MediaRequest) =>sampleMediaRequestStatus.includes(f.status))
    //   if(refreshSampleMediaRequest){
    //     refreshSampleMediaRequest.forEach((singleRefreshMediaRequest) => { 
    //         this.props.refreshSampleMediaRequest(singleRefreshMediaRequest);
    //     })
    //   }
    // }
    const userType : string= this.props.profile ? this.props.profile.family_name : "";
    if(userType.toUpperCase() === userTypeEnums.INTERNAL_USER.toUpperCase()){
      const lpcode : string = this.props.sampleMediaRequests.length ? this.props.sampleMediaRequests[0].learningProviderCode : "";
      if(lpcode.length){
        this.props.getMediaSetsByLearningProviderCode(lpcode);
      }
    } else {
      const learningProviderId=window.localStorage.getItem("selectedUserId");
      if(learningProviderId !== "" && learningProviderId !== "0")
      {
        this.props.refreshMediaRequest(learningProviderId!);
      }
      // this.props.refreshMediaRequest(this.props.learningProviderId);
    }
  }
}

export default SampleMediaRequests;
