export const GLOBAL_ERROR = 'GLOBAL_ERROR';
export type GLOBAL_ERROR = typeof GLOBAL_ERROR;

export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export type BEGIN_AJAX_CALL = typeof BEGIN_AJAX_CALL;

export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';
export type AJAX_CALL_ERROR = typeof AJAX_CALL_ERROR;

export const GET_ASESSMENT_ASSOCIATE = 'GET_ASESSMENT_ASSOCIATE';
export type GET_ASESSMENT_ASSOCIATE = typeof GET_ASESSMENT_ASSOCIATE;

export const CLOSE_SHOW_LEARNER_ERROR_MODAL = 'CLOSE_SHOW_LEARNER_ERROR_MODAL';
export type CLOSE_SHOW_LEARNER_ERROR_MODAL ='CLOSE_SHOW_LEARNER_ERROR_MODAL';


export const ADD_LEARNER_ERROR= 'ADD_LEARNER_ERROR';
export type ADD_LEARNER_ERROR = typeof ADD_LEARNER_ERROR;

export const GET_ASESSMENT_ASSOCIATE_SUCCESS = 'GET_ASESSMENT_ASSOCIATE_SUCCESS';
export type GET_ASESSMENT_ASSOCIATE_SUCCESS = typeof GET_ASESSMENT_ASSOCIATE_SUCCESS;

export const LOAD_AVAILABILITIES_SUCCESS = 'LOAD_AVAILABILITIES_SUCCESS';
export type LOAD_AVAILABILITIES_SUCCESS = typeof LOAD_AVAILABILITIES_SUCCESS;

export const GET_SAMPLE_MEDIA_REQUESTS = 'GET_SAMPLE_MEDIA_REQUESTS ';
export type GET_SAMPLE_MEDIA_REQUESTS = typeof GET_SAMPLE_MEDIA_REQUESTS ;

export const GET_SAMPLE_MEDIA_REQUESTS_SUCCESS = 'GET_SAMPLE_MEDIA_REQUESTS_SUCCESS';
export type GET_SAMPLE_MEDIA_REQUESTS_SUCCESS = typeof GET_SAMPLE_MEDIA_REQUESTS_SUCCESS;

export const GET_SAMPLE_MEDIA_REQUESTS_STARTED = 'GET_SAMPLE_MEDIA_REQUESTS_STARTED';
export type GET_SAMPLE_MEDIA_REQUESTS_STARTED = typeof GET_SAMPLE_MEDIA_REQUESTS_STARTED;

export const GET_SAMPLE_MEDIA_REQUESTS_COMPLETED = 'GET_SAMPLE_MEDIA_REQUESTS_COMPLETED';
export type GET_SAMPLE_MEDIA_REQUESTS_COMPLETED = typeof GET_SAMPLE_MEDIA_REQUESTS_COMPLETED;

export const GET_ENV_SUCCESS = 'GET_ENV_SUCCESS';
export type GET_ENV_SUCCESS = typeof GET_ENV_SUCCESS;

export const GET_USER_DETAILS_BY_FORGEROCK_REQUESTS_SUCCESS = 'GET_USER_DETAILS_BY_FORGEROCK_REQUESTS_SUCCESS';
export type GET_USER_DETAILS_BY_FORGEROCK_REQUESTS_SUCCESS = typeof GET_USER_DETAILS_BY_FORGEROCK_REQUESTS_SUCCESS;

export const CREATE_MEDIA_SET = 'CREATE_MEDIA_SET';
export type CREATE_MEDIA_SET = typeof CREATE_MEDIA_SET;

export const CREATE_MEDIA_SET_SUCCESS = 'CREATE_MEDIA_SET_SUCCESS';
export type CREATE_MEDIA_SET_SUCCESS = typeof CREATE_MEDIA_SET_SUCCESS;

export const SUBMIT_MEDIA_SET_SUCCESS = 'SUBMIT_MEDIA_SET_SUCCESS';
export type SUBMIT_MEDIA_SET_SUCCESS = typeof SUBMIT_MEDIA_SET_SUCCESS;

export const CREATE_MEDIA_FILE_SET_SUCCESS = 'CREATE_MEDIA_FILE_SET_SUCCESS';
export type CREATE_MEDIA_FILE_SET_SUCCESS = typeof CREATE_MEDIA_FILE_SET_SUCCESS;

export const AUTH_USER = 'AUTH_USER';
export type AUTH_USER = typeof AUTH_USER;

export const UNAUTH_USER = 'UNAUTH_USER';
export type UNAUTH_USER = typeof UNAUTH_USER;

export const AUTH_IN_PROGRESS = 'AUTH_IN_PROGRESS';
export type AUTH_IN_PROGRESS = typeof AUTH_IN_PROGRESS;

export const AUTH_ERROR = 'AUTH_ERROR';
export type AUTH_ERROR = typeof AUTH_ERROR;

export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export type FORGOT_SUCCESS = typeof FORGOT_SUCCESS;

export const CLEARDOWN = 'CLEARDOWN';
export type CLEARDOWN = typeof CLEARDOWN;

export const CALLBACK_IN_PROGRESS = 'CALLBACK_IN_PROGRESS';
export type CALLBACK_IN_PROGRESS = typeof CALLBACK_IN_PROGRESS;

export const CALLBACK_COMPLETE = 'CALLBACK_COMPLETE';
export type CALLBACK_COMPLETE = typeof CALLBACK_COMPLETE;

export const UPLOAD_MEDIA_SUCCESS = 'UPLOAD_MEDIA_SUCCESS';
export type UPLOAD_MEDIA_SUCCESS = typeof UPLOAD_MEDIA_SUCCESS;

export const UPLOAD_MEDIA_PROGRESS = 'UPLOAD_MEDIA_PROGRESS';
export type UPLOAD_MEDIA_PROGRESS = typeof UPLOAD_MEDIA_PROGRESS;

export const UPLOAD_MEDIA_CANCELLED = 'UPLOAD_MEDIA_CANCELLED';
export type UPLOAD_MEDIA_CANCELLED = typeof UPLOAD_MEDIA_CANCELLED

export const VALIDATE_USER_PERMISSIONS_SUCCESS = 'VALIDATE_USER_PERMISSIONS_SUCCESS';
export type VALIDATE_USER_PERMISSIONS_SUCCESS= typeof VALIDATE_USER_PERMISSIONS_SUCCESS

export const UPLOAD_MEDIA_ERROR = 'UPLOAD_MEDIA_ERROR';
export type UPLOAD_MEDIA_ERROR = typeof UPLOAD_MEDIA_ERROR

export const MEDIASET_UPDATED_SUCCESS = 'MEDIASET_UPDATED_SUCCESS';
export type MEDIASET_UPDATED_SUCCESS = typeof MEDIASET_UPDATED_SUCCESS;

export const FILE_REMOVE_SUCCESS = 'FILE_REMOVE_SUCCESS';
export type FILE_REMOVE_SUCCESS = typeof FILE_REMOVE_SUCCESS;

export const ADD_GLOBAL_ALERT = 'ADD_GLOBAL_ALERT';
export type ADD_GLOBAL_ALERT = typeof ADD_GLOBAL_ALERT;

export const REMOVE_GLOBAL_ALERT = 'REMOVE_GLOBAL_ALERT';
export type REMOVE_GLOBAL_ALERT = typeof REMOVE_GLOBAL_ALERT;

export const UPDATE_SELECTED_USER_ID = 'UPDATE_SELECTED_USER_ID';
export type UPDATE_SELECTED_USER_ID = typeof UPDATE_SELECTED_USER_ID;

export const SESSION_ALIVE_FAILURE = 'SESSION_ALIVE_FAILURE';
export type SESSION_ALIVE_FAILURE = typeof SESSION_ALIVE_FAILURE;

export const SESSION_ALIVE_SUCCESS = 'SESSION_ALIVE_SUCCESS';
export type SESSION_ALIVE_SUCCESS = typeof SESSION_ALIVE_SUCCESS;
