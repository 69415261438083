import { connect } from "react-redux";
import { ThunkDispatch } from 'redux-thunk';
import { SelectLearningProvider, StateProps,DispatchProps } from "./select-learning-provider";
import { StoreState } from "../../store/store-state";
// import { MediaUploadStatus } from "../../models/media-upload";
import { MediaRequest } from '../../models/media-request';

import _ from "lodash";
import {
  getMediaSetsByLearningProviderCode,
  
  getSampleMediaRquestsSuccessAction
} from "../../actions/select-learning-provider.actions";

import {
  getSampleMediaRequests,
  SampleMediaRequestsAction,
  
} from  '../../features/sample-media-request/shared/sample-media-request.actions'; 


const mapStateToProps = (state: StoreState): StateProps => {  
  // SampleMediaRequestsList: state.sampleMediaRequests
  // return {mediaUploadStatus: []}
  const sampleMediaRequests = _.orderBy(state.sampleMediaRequests, (o: MediaRequest) => o.qualificationName, 'asc');
 
  return {
    
    sampleMediaRequests,
    learningProviderId: state.learningProvider.id
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<StoreState, void, getSampleMediaRquestsSuccessAction|SampleMediaRequestsAction>): DispatchProps => ({
  getMediaSetsByLearningProviderCode: (learningProviderCode: string) =>
    dispatch(getMediaSetsByLearningProviderCode(learningProviderCode)),
    getSampleMediaRequests: learningProviderId => dispatch(getSampleMediaRequests(learningProviderId)),
});

export default connect<StateProps>(
  mapStateToProps,
  mapDispatchToProps
)(SelectLearningProvider);

