import * as React from 'react';
import { RouteComponentProps} from 'react-router';
// import * as toastr from 'toastr';

import { AssessmentAssociate } from '../../models/assessment-associate';
import AssessmentAssociateSearch from './shared/assessment-associate-availability-search';

export interface StateProps {
  assessmentAssociate: AssessmentAssociate | null;
}

export interface DispatchProps {
  getAssessmentAssociate: (aaNumber: string) => Promise<void>;
}

export type OwnProps = RouteComponentProps<{}>;

type Props = DispatchProps & StateProps & OwnProps;

interface State {
  isBusy: boolean;
  assessmentAssociateNumber: string;
}

export class AssessmentAssociateAvailability extends React.Component<
  Props,
  State
> {

  constructor(props: Props) {
    super(props);

    this.state = {
      isBusy: false,
      assessmentAssociateNumber: ''
    };

    this.UpdateAssessmentAssociateNumber = this.UpdateAssessmentAssociateNumber.bind(
      this
    );
    this.GetAssessmentAssociate = this.GetAssessmentAssociate.bind(this);
    this.redirectToAvailabilityManager = this.redirectToAvailabilityManager.bind(
      this
    );
  }

  render() {
    return (
      <div>
        <div className="pe-template__single">
          <h1 className="pe-page-title">AA Availability</h1>
        </div>
        <div className="pe-template__single content">
          <AssessmentAssociateSearch
            onChange={this.UpdateAssessmentAssociateNumber}
            onSearch={this.GetAssessmentAssociate}
            isBusy={this.state.isBusy}
            aaNumber={this.state.assessmentAssociateNumber}
          />
        </div>
      </div>
    );
  }

  UpdateAssessmentAssociateNumber(event: React.FormEvent<HTMLInputElement>) {
    const element = event.target as HTMLInputElement;
    return this.setState({ assessmentAssociateNumber: element.value });
  }

  GetAssessmentAssociate(event: Event) {
    event.preventDefault();

    this.setState({ isBusy: true });

    this.props
      .getAssessmentAssociate(this.state.assessmentAssociateNumber)
      .then(() => {
        this.setState({
          isBusy: false
        });
        this.redirectToAvailabilityManager();
      })
      .catch((error: string) => {
        this.setState({ isBusy: false });
        console.log(error)
        // toastr.error(error);
      });
  }

  redirectToAvailabilityManager() {
    this.props.history.push(
      `/availability/${this.state.assessmentAssociateNumber}`
    );
  }
}

export default AssessmentAssociateAvailability;
