import React from 'react';
import {Button} from 'ukas.uux.react-components';
interface ConfirmationModalProps {
    cancelBtnHandler : ( event: React.UIEvent<HTMLSpanElement>) => void;
    successBtnHandler : ( event: React.UIEvent<HTMLSpanElement>) => void;
    conmfirmMsgRender:() => JSX.Element;
}

const LastLearnerConfirmationModalGeneric = ({ successBtnHandler, cancelBtnHandler, conmfirmMsgRender }: ConfirmationModalProps) =>{
    require('./last-learner-confirmation-modal.css');
    return ( 
    <div className="second-modal-positioning">
      <div >
        {conmfirmMsgRender()}
      </div>
      
      <div className="pull-right"><br /><br />
        <Button
             onClick={cancelBtnHandler}>
            Cancel
        </Button>&nbsp;&nbsp;&nbsp;&nbsp;
        
        <Button variant ="primary" 
               onClick={successBtnHandler}>
            Ok
        </Button>
        <br /><br />         
      </div>
    </div>
   
  );
};

export default LastLearnerConfirmationModalGeneric;
