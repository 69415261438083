import axios, { AxiosRequestConfig } from "axios";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import * as action_types from "./action-types";
import { StoreState } from "../store/store-state";
import { S3UploadMultipart } from './s3-upload-multipart';
import { MediaUploadStatus, MediaUploadFile } from '../models/media-upload';
import {
    ajaxCallError,
    beginAjaxCall,
    AjaxCallActions
  } from "./ajax-status.actions";
import { globalError, GlobalErrorActions } from "./global-error.actions";
import { addGlobalAlertAction, GlobalAlertListActions } from "../features/global-alert-list/shared/global-alert-list.actions";
import config from "../app-config";
import { addMediaSetFile } from "./media-set.actions";
import { CreateMediaRequestFile } from "../models/media-request";
import { GlobalAlertMessage } from "../models/global-alert-message";
import AuthForgeRock from '../actions/auth/auth';
const auth = new AuthForgeRock();
interface UploadMediaSuccessAction extends Action {
    type: action_types.UPLOAD_MEDIA_SUCCESS;
    status: MediaUploadStatus;
}

interface UploadMediaProgressAction extends Action {
    type: action_types.UPLOAD_MEDIA_PROGRESS;
    status: MediaUploadStatus;
}

interface UploadMediaCancelledAction extends Action {
    type: action_types.UPLOAD_MEDIA_CANCELLED;
    status: MediaUploadStatus;
}

interface UploadMediaErrorAction extends Action {
    type: action_types.UPLOAD_MEDIA_ERROR;
    status: MediaUploadStatus;
}

export type MediaUploadActions =
  | UploadMediaSuccessAction
  | UploadMediaProgressAction
  | UploadMediaCancelledAction
  | UploadMediaErrorAction
  | AjaxCallActions
  | GlobalErrorActions
  | GlobalAlertListActions;

export const uploadMediaSuccessAction = (
    status: MediaUploadStatus
): UploadMediaSuccessAction => ({
    type: action_types.UPLOAD_MEDIA_SUCCESS,
    status
});

export const uploadMediaProgressAction = (
    status: MediaUploadStatus
): UploadMediaProgressAction => ({
    type: action_types.UPLOAD_MEDIA_PROGRESS,
    status
});

export const uploadMediaCancelledAction = (
    status: MediaUploadStatus
): UploadMediaCancelledAction => ({
    type: action_types.UPLOAD_MEDIA_CANCELLED,
    status
});

export const uploadMediaErrorAction = (
    status: MediaUploadStatus
): UploadMediaErrorAction => ({
    type: action_types.UPLOAD_MEDIA_ERROR,
    status
});


export const uploadMedia = (mediaUploadFile: MediaUploadFile) => {
  return (dispatch: ThunkDispatch<StoreState, void, MediaUploadActions>) => {

    const cleanedFilename = mediaUploadFile.file.name.replace(/[^\w\d_\-\.]+/ig, '');

    // const filekey = mediaUploadFile.learningProviderId + '/' 
    //                 + mediaUploadFile.mediaSetId + '/' 
    //                 + mediaUploadFile.unitId + '/'
    //                 + mediaUploadFile.learnerId + '/' 
    //                 + cleanedFilename;
    const filekey =`${mediaUploadFile.learningProviderId}/${mediaUploadFile.mediaSetId}/${mediaUploadFile.unitId}/${mediaUploadFile.learnerId && mediaUploadFile.learnerId+"/"}${cleanedFilename}` ;
    console.log("filekey", filekey)
    dispatch(beginAjaxCall());

    const axiosConfig: AxiosRequestConfig = {
        headers: { 
            "content-type": "application/json",
            "Authorization": `Bearer ${auth.getAccessToken()}`,
            'x-id-token': `${auth.getIdToken()}`
        }
    };
    return axios
      .post(
        `${config.API_GATEWAY.URL}/media-upload-credentials`,
        JSON.stringify({
            learningProviderId: mediaUploadFile.learningProviderId,
            mediaSetId: mediaUploadFile.mediaSetId,
            learnerId:mediaUploadFile && mediaUploadFile.learnerId ? mediaUploadFile.learnerId : "",
            fileName:cleanedFilename,
            fileKey: filekey,
            unitId: mediaUploadFile.unitId
        }),
        axiosConfig
      )
      .then(postResponse => {                

        const credentials = postResponse.data;
        console.log("credentials : " +credentials);

        const uploader = new S3UploadMultipart(credentials, mediaUploadFile, filekey);   
    
        const cancelUpload = () => {
            const status = new MediaUploadStatus();
            status.fileKey = filekey;
            status.fileName = mediaUploadFile.file.name;
            status.percentage = 0;
            status.aborted = true;            
            uploader.abortUpload();
            dispatch(uploadMediaCancelledAction(status));
        };

        const startUpload = () => {
            uploader.startUpload();

            const status = new MediaUploadStatus();
            status.fileKey = filekey
            status.fileName = mediaUploadFile.file.name;
            status.percentage = 0;
            status.cancelUpload = cancelUpload;
            status.inProgress = true;
            dispatch(uploadMediaProgressAction(status));
        };

        return Promise.resolve()
        .then(() => {
            uploader.onStart = () => {
                const status = new MediaUploadStatus();
                status.fileKey = filekey
                status.fileName = mediaUploadFile.file.name;
                status.percentage = 0;
                status.cancelUpload = cancelUpload;
                status.startUpload = startUpload;
                status.inProgress = false;
                dispatch(uploadMediaProgressAction(status));
            } 
            uploader.onProgress = (percentage: number) => {
                const status = new MediaUploadStatus();
                status.fileKey = filekey
                status.fileName = mediaUploadFile.file.name;
                status.percentage = percentage;
                status.cancelUpload = cancelUpload;
                status.inProgress = true;
                dispatch(uploadMediaProgressAction(status));
            };
            uploader.onFinishS3Put = () => {
                const mediaSetFile = new CreateMediaRequestFile();
                mediaSetFile.fileId = filekey;
                mediaSetFile.fileKey = filekey;
                mediaSetFile.fileName = mediaUploadFile.file.name;
                mediaSetFile.learningProviderId = mediaUploadFile.learningProviderId;
                mediaSetFile.mediaSetId = mediaUploadFile.mediaSetId;
                mediaSetFile.unitId = mediaUploadFile.unitId;
                mediaSetFile.learnerIds = mediaUploadFile.learnerId ? [mediaUploadFile.learnerId] : [];
                dispatch(addMediaSetFile(mediaSetFile)).then(() => {
                    const status = new MediaUploadStatus();
                    status.fileKey = filekey;
                    status.fileName = mediaUploadFile.file.name;
                    status.percentage = 1;
                    status.completed = true;
                    dispatch(uploadMediaSuccessAction(status)); 
                });              
            };
            uploader.onError = (error: string) => {
                const status = new MediaUploadStatus();
                status.fileKey = filekey;
                status.fileName = mediaUploadFile.file.name;
                status.percentage = 0;
                status.error = new Error(error);
                dispatch(uploadMediaErrorAction(status));
            };
            uploader.addFileToUpload();
            return; 
        })
        .catch((error: Error) => { 
            const status = new MediaUploadStatus();
            status.fileKey = filekey;
            status.fileName = mediaUploadFile.file.name;
            status.percentage = 0;
            status.error = error;
            dispatch(uploadMediaErrorAction(status)); 
        });
      })
      .catch(error => {
        dispatch(ajaxCallError(error));

        switch(error.response.status) {
            case 415:
                const fileFormatMessage = new GlobalAlertMessage();
                fileFormatMessage.title = mediaUploadFile.file.name;
                fileFormatMessage.message = 'File is in an unsupported format.'
                dispatch(addGlobalAlertAction(fileFormatMessage))
                break;
            case 409:
                const duplicateMessage = new GlobalAlertMessage();
                duplicateMessage.title = mediaUploadFile.file.name;
                duplicateMessage.message = 'File already uploaded, please either upload the file with a different name or delete the existing file and then reupload.';
                dispatch(addGlobalAlertAction(duplicateMessage))
                break;
            default:
                dispatch(globalError(error));
                break;
        }
        
        throw error;
      });
    };    
};



